import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './AutocompleteStyle.css';
import { get } from './../../services/Apicall';

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
  };

  static defaultProps = {
    suggestions: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: this.props.initValue,
    };
  }
  componentDidMount() {
    //console.log(this.state.userInput);
    //console.log(this.props);
    this.setState({ userInput: this.props.initValue });

    //console.log(this.state.userInput);
  }
  // Event fired when the input value is changed
  onChange = (e) => {
    this.setState({ userInput: e.currentTarget.value });
    this.props.setAutoComplete(e.currentTarget.value);
    const userInput = e.target.value;
    if (userInput.length >= 2)
      get(this.props.url, { key: userInput, limit: 40 }).then((result) => {
        let responseJson = result;
        if (responseJson.data && responseJson.error === false) {
          // this.setState({ data: responseJson.data });

          const suggestions = responseJson.data;

          // Filter our suggestions that don't contain the user's input
          const filteredSuggestions = suggestions.filter(
            (suggestion) =>
              suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
          );

          // Update the user input and filtered suggestions, reset the active
          // suggestion and make sure the suggestions are shown
          this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            // userInput: e.currentTarget.value
          });
        }
        // else if (responseJson.error === true) {
        //    notify.show('Something went wrong while loading data.', 'error');
        // }
      });
  };

  // Event fired when the user clicks on a suggestion
  onClick = (e) => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
    });
    this.props.setAutoComplete(e.currentTarget.innerText);
  };
  onBlur = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    setTimeout(
      function () {
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          // userInput: filteredSuggestions[activeSuggestion]
        });
      }.bind(this),
      500
    );
  };

  // Event fired when the user presses a key down
  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      if (activeSuggestion !== 0) {
        e.preventDefault();
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: filteredSuggestions[activeSuggestion],
        });
        //this.props.setAutoComplete( filteredSuggestions[activeSuggestion])//Added by AZ //removed by MP
        // this.props.setAutoComplete(e.currentTarget.innerText)//revmoved by AZ
        this.props.setAutoComplete(e.currentTarget.innerText);//added by MP
      }
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      onBlur,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active';
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          value={this.props.initValue}
          className={this.props.className ? this.props.className : ''}
          placeholder={this.props.placeholder ? this.props.placeholder : ''}
          maxLength={this.props.maxLength ? this.props.maxLength : ''}
        // required={this.props.required?this.props.required:'false'}
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
