import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header_after_login from './../common/Header_after_login';
import Footer from './../common/Footer';
import { get, post } from './../../services/Apicall';

//import {loader} from './../../services/Loader/Loader';

import Loader from './../form/Loader';
import { notify } from 'react-notify-toast';
import moment from 'moment';
import ReactGA from 'react-ga';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      result: [],
    };
  }

  componentDidMount() {

    //console.log('Trigger .... Page view GA-- ' + window.location.pathname + window.location.search);
    // This line will trigger on a route change
    ReactGA.initialize('UA-151243375-1');
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);


    this.setState({ isLoading: true });
    // loader.show();
    get(`v2/emp/dashboard`, {}, true).then((result) => {
      let responseJson = result;
      // loader.hide();
      if (responseJson.data) {
        this.setState({
          isLoading: false,
          result: responseJson.data,
        });
      } else {
        this.setState({ isLoading: false });
        notify.show('Something went wrong while geting Data!', 'warning');
      }
    });
  }

  render() {
    const { result, isLoading } = this.state;
    const colorClass = [
      'greenBar',
      'orangeBar',
      'blueBar',
      'greenBar',
      'orangeBar',
      'purpleBar',
    ];
    /* isLoading ? (
      <p><div className="no_record loader_with"><img src="/../images/loader.svg"/></div></p>
    ) : (
  result && (*/
    return <>
      {isLoading && <Loader pageLoader={isLoading} />}
      <div className="main-body">
        <Header_after_login />

        <div className="container-fluid">
          <div id="rightside" className="rside">
            <div className="body_main">
              <div className="main">
                <div className="recur_itment_summ_ary">
                  <div className="recur_itment_summ_ary_block">

                    <div className="jp_credit">
                      {/* <div className="all_rec_summ_blc half_two">
                            <div className="all_rec_summ_blc_detail">
                              <p className="cou_nter">
                                {localStorage.getItem('emp_used_download')} / {localStorage.getItem('emp_total_download')}
                              </p>
                              <p className="cou_nter_na_me">Download</p>
                            </div>
                        </div> */}
                      <button type="button" class="btn btn-primary">
                        Expires On<span class="badge badge-light">{result.plan_end && result.plan_end != '0000-00-00 00:00:00' ? moment(result.plan_end).format('Do MMMM YYYY') : 'NA'}</span>
                      </button>
                      <button type="button" class="btn btn-primary">
                        Job Posts <span class="badge badge-light">{localStorage.getItem('emp_used_jobpost')} / {localStorage.getItem('emp_total_jobpost')}</span>
                      </button>
                      <button type="button" class="btn btn-primary">
                        Downloads  <span class="badge badge-light">{localStorage.getItem('emp_used_download')} / {localStorage.getItem('emp_total_download')}</span>
                      </button>


                      {/* <div className="all_rec_summ_blc half_two">
                            <div className="all_rec_summ_blc_detail">
                              <p className="cou_nter">
                                {localStorage.getItem('emp_used_jobpost')} / {localStorage.getItem('emp_total_jobpost')}
                              </p>
                              <p className="cou_nter_na_me">Job Post</p>
                            </div>
                        </div> */}
                    </div>

                    <h1>Recruitment Summary</h1>

                    {/* <!-- Recruitment blocks --> */}
                    <div className="all_rec_summ_blc">
                    <Link to="/employer/my_jobs">
                      <div className="all_rec_summ_blc_detail">
                        <p className="cou_nter">
                          {result.counts && result.counts.active_jobs}
                        </p>

                        <p className="cou_nter_na_me">Active Jobs</p>
                      </div>
                      </Link>
                    </div>
                    {/* <!-- End of Recruitment blocks -->
                               <!-- Recruitment blocks --> */}
                    <div className="all_rec_summ_blc">
                    <Link to="/employer/new-applications">
                      <div className="all_rec_summ_blc_detail">
                        <p className="cou_nter">
                          {result.counts && result.counts.new_application}
                        </p>

                        <p className="cou_nter_na_me">New Applications</p>
                      </div>
                      </Link>
                    </div>
                    {/* <!-- End of Recruitment blocks -->
                               <!-- Recruitment blocks --> */}
                    <div className="all_rec_summ_blc">
                    <Link to="/employer/Saved_candidate_history">
                      <div className="all_rec_summ_blc_detail">
                        <p className="cou_nter">
                          {result.counts && result.counts.to_be_reviewed}
                        </p>

                        <p className="cou_nter_na_me">
                          Candidates to be reviewed
                            </p>
                      </div>
                      </Link>
                    </div>
                    {/* <!-- End of Recruitment blocks -->
                               <!-- Recruitment blocks --> */}
                    <div className="all_rec_summ_blc">
                    <Link to="/employer/shortlisted-applications">
                      <div className="all_rec_summ_blc_detail">
                        <p className="cou_nter">
                          {result.counts && result.counts.shortlisted}
                        </p>

                        <p className="cou_nter_na_me">
                          Shortlisted Candidates 
                            </p>
                      </div>
                      </Link>
                    </div>


                    {/* <!-- End of Recruitment blocks --> */}
                  </div>



                  <div className="recu_ritment_overview">
                    {/*   <h2>Recruitment Overview</h2>

                        <div className="recuritment_graph">
                        <div className="recur_itment_overview_gra_ph">
                          <h3>Active Jobs</h3>
                          <div className="recur_itment_over_view_graph_section recuroveregrapsec">
                            <div className="chartBarsWrap">
                              <div className="chartBars chartBars2">
                                <ul className="numbers">
                                  <li>
                                    <span>100%</span>
                                  </li>
                                  <li>
                                    <span>80%</span>
                                  </li>
                                  <li>
                                    <span>60%</span>
                                  </li>
                                  <li>
                                    <span>40%</span>
                                  </li>
                                  <li>
                                    <span>20%</span>
                                  </li>
                                  <li>
                                    <span>0%</span>
                                  </li>
                                </ul>
                                <ul className="bars">
                                  {result.overview.active_jobs.map(
                                    (item, key) => (
                                      <li>
                                        <div
                                          data-percentage={`'${item.value}'`}
                                          className={`bar ${colorClass[key]} trigger`}
                                          style={{ height: `${item.value}%` }}
                                        ></div>
                                        <b>{item.label}</b>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                       
                        <div className="recur_itment_overview_gra_ph recurmentoverviewgraph">
                          <h3>Candidates</h3>
                          <div className="recur_itment_over_view_graph_section recurmentoverviewgraphsec">
                            <div className="chartBarsWrap">
                              <div className="chartBars chartBars2 chartBars3">
                                <ul className="numbers">
                                  <li>
                                    <span>100%</span>
                                  </li>
                                  <li>
                                    <span>80%</span>
                                  </li>
                                  <li>
                                    <span>60%</span>
                                  </li>
                                  <li>
                                    <span>40%</span>
                                  </li>
                                  <li>
                                    <span>20%</span>
                                  </li>
                                  <li>
                                    <span>0%</span>
                                  </li>
                                </ul>
                                <ul className="bars">
                                  {result.overview.candidate.map(
                                    (item, key) => (
                                      <li>
                                        <div
                                          data-percentage={`'${item.value}'`}
                                          className={`bar ${colorClass[key]} trigger`}
                                          style={{ height: `${item.value}%` }}
                                        ></div>
                                        <b>{item.label}</b>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
*/}

                    <div className="deskboard_recent_jobs">
                      <div className="deskboard_recent_jobs_title_link">
                        <h2>Recent Jobs</h2>
                        <Link className="deskrecsee_all" to="/employer/my_jobs">
                          <span className="see_all"><strong>({result.counts && result.counts.active_jobs})</strong> See All</span>
                        </Link>
                        <Link className="deskrecsee_all" to="#" onClick={() => this.componentDidMount()}>
                          <div className="recent_jobs_title_section refresh_btn_here ">
                            <div className="rec_ent_job_title_name">
                              <button>
                                <i className="fas fa-sync-alt"></i>Refresh
                                  </button>
                            </div>
                          </div>
                        </Link>

                      </div>
                      {/* <!-- recent jobs 1 --> */}
                      {
                        result.recentJobs && result.recentJobs.length > 0 ?
                          result.recentJobs.map((item, key) => (
                            <div className="all_deskoard_recent_jobs">
                              <div className="deshboard_inner">
                                <div className="recent_left_jobs_detail">
                                  <div className="recent_jobs_title_section">
                                    <div className="rec_ent_job_title_icon">
                                      <i className="fas fa-tag"></i>
                                    </div>
                                    <div className="rec_ent_job_title_name">
                                      <span>Job Title : </span>
                                      <span>
                                        <strong><Link to={"/employer/job_view/"+(item.slug?item.slug:item.id)}> {item.job_title}</Link></strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="recent_jobs_title_section">
                                    <div className="rec_ent_job_title_icon">
                                      <i className="fa" aria-hidden="true">
                                        &#xf1ad;
                                    </i>
                                    </div>
                                    <div className="rec_ent_job_title_name">
                                      <span>Comapny Name : </span>
                                      <span>
                                        <strong>{item.company_name}</strong>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="recent_jobs_title_section">
                                    <div className="rec_ent_job_title_icon">
                                      <i className="fa">&#xf041;</i>
                                    </div>
                                    <div className="rec_ent_job_title_name">
                                      <span>Location : </span>
                                      <span>
                                        <strong>{item.location.join('; ')}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="recent_jobs_title_section pull-left">
                                    <div className="rec_ent_job_title_icon">
                                      <i className="fas fa-business-time"></i>
                                    </div>
                                    <div className="rec_ent_job_title_name">
                                      <span>Experience : </span>
                                      <span>
                                        <strong>
                                          {item.experience_min}
                                          {item.experience_min && item.experience_max ? ' to ' :
                                            item.experience_min && !item.experience_max ? '+' :
                                              !item.experience_min && item.experience_max ? 'upto ' : ''
                                          }
                                          {item.experience_max}
                                          {item.experience_min || item.experience_max ? ' Year ' : ''}
                                        </strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="recent_jobs_title_section pull-left">
                                    <div className="rec_ent_job_title_icon">
                                      <i className="fas fa-history"></i>
                                    </div>
                                    <div className="rec_ent_job_title_name">
                                      <span> Posted on : </span>
                                      <span>
                                        <strong>
                                          {moment(item.post_date.date).format('Do MMMM YYYY')}
                                        </strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="recent_right_jobs_detail">
                                  <div className="recent_jobs_title_section">
                                    <div className="rec_ent_job_title_icon">
                                      <i className="fas fa-folder-open"></i>
                                    </div>
                                    <div className="rec_ent_job_title_name">
                                      <Link to={"/employer/applied_job_listing/"+item.id}>
                                      <span> {item.application} </span>
                                      <span>
                                        <strong> Application</strong>
                                      </span>
                                      </Link>
                                    </div>
                                  </div>

                                  <div className="recent_jobs_title_section">
                                    <div className="rec_ent_job_title_icon">
                                      <i className="fas fa-folder-plus"></i>
                                    </div>
                                    <div className="rec_ent_job_title_name">
                                      <Link to={"/employer/applied_job_listing/"+item.id}>
                                      <span> {item.newApplication} </span>
                                      <span>
                                        <strong> New</strong>
                                      </span>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )) : <p>
                            <div className="no_record">
                              <div className="no_record_container">
                                <div className="tip_icon">
                                  <i className="fa fa-lightbulb"></i>
                                </div>
                                <div className="interview_add_">
                                  <span>There is No Recent Posted Job<br /> Want to Post a job</span>
                                  <Link to='/employer/post_job'><button className="no_interview">Post Job</button></Link>
                                </div>
                              </div>
                            </div>
                          </p>}

                      {/* <!-- end of recent jobs 1 --> */}
                    </div>
                    {/* 
                                <!--end of recent jobs -->


 <!-- recent shortlist jobs --> */}

                    <div className="deskboard_recent_jobs recent_shortlisted">
                      <div className="deskboard_recent_jobs_title_link">
                        <h2>Recent Shortlisted Candidates For Interview</h2>
                      </div>
                      {result.recentShortlisted && result.recentShortlisted.length > 0 ?
                        result.recentShortlisted.map((item, key) => (
                          <div className="all_deskoard_recent_jobs">
                            <div className="deshboard_inner">
                              <div className="recent_left_jobs_detail">
                                <div className="recent_jobs_title_section">
                                  <div className="rec_ent_job_title_icon">
                                    <i className="fas fa-user"></i>
                                  </div>
                                  <div className="rec_ent_job_title_name">
                                    <span>Candiadate Name : </span>
                                    <span>
                                      {/* <Link to=""> */}
                                        <strong>{`${item.candidate.first_name} ${item.candidate.last_name}`}</strong>
                                      {/* </Link> */}
                                    </span>
                                  </div>
                                </div>
                                <div className="recent_jobs_title_section">
                                  <div className="rec_ent_job_title_icon">
                                    <i className="fas fa-history"></i>
                                  </div>
                                  <div className="rec_ent_job_title_name">
                                    <span>Applied On : </span>
                                    <span>
                                      <strong>
                                        {' '}
                                        {moment(item.applied_on).format(
                                          'Do MMMM YYYY'
                                        )}
                                      </strong>
                                    </span>
                                  </div>
                                </div>

                                <div className="recent_jobs_title_section">
                                  <div className="rec_ent_job_title_icon">
                                    <i className="fa">&#xf041;</i>
                                  </div>
                                  <div className="rec_ent_job_title_name">
                                    <span>Location : </span>
                                    <span>
                                      <strong>{`${item.candidate.city_name} , ${item.candidate.country_name}`}</strong>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="recent_right_jobs_detail">
                                <div className="recent_jobs_title_section">
                                  <div className="rec_ent_job_title_icon">
                                    <i className="fas fa-tag"></i>
                                  </div>
                                  <div className="rec_ent_job_title_name">
                                    <span>Job Title : </span>
                                    <span>
                                      <strong>
                                        {' '}
                                        {item.candidate.current_job_title}
                                      </strong>
                                    </span>
                                  </div>
                                </div>

                                <div className="recent_jobs_title_section">
                                  <div className="rec_ent_job_title_icon">
                                    <i className="fa" aria-hidden="true"> &#xf1ad; </i>
                                  </div>
                                  <div className="rec_ent_job_title_name">
                                    <span>Company name : </span>
                                    <span>
                                      <strong>
                                        {
                                          item.candidate.work_exp.find(({ is_current }) => is_current !== 0) ?
                                            item.candidate.work_exp.find(({ is_current }) => is_current !== 0).company : ''
                                          // item.candidate.work_exp
                                          //   .map(({ company,is_current }) => company)
                                          //   .join(' ,')
                                        }
                                      </strong>
                                    </span>
                                  </div>
                                </div>

                                <div className="recent_jobs_title_section">
                                  <div className="rec_ent_job_title_icon">
                                    <i className="fas fa-history"></i>
                                  </div>
                                  <div className="rec_ent_job_title_name">
                                    <span> Posted on : </span>
                                    <span>
                                      <strong>
                                        {moment(item.shortlisted_on).format(
                                          'Do MMMM YYYY'
                                        )}
                                      </strong>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )) : <p>
                          <div className="no_record">
                            <div className="no_record_container">
                              <div className="tip_icon">
                                <i className="fa fa-lightbulb"></i>
                              </div>
                              <div className="interview_add_">
                                <span>No Candidate is shortlisted for Interview <br />Check Application against posted job to shortlist Candidate</span>
                                <Link to='/employer/my_jobs'> <button className="no_interview">My Jobs</button></Link>
                              </div>
                            </div>
                          </div>
                        </p>}
                    </div>

                    <div className="up_com_ing_inter_view">
                      <div className="deskboard_recent_jobs_title_link">
                        <h2>Upcoming Interviews</h2>
                        <Link className="deskrecsee_all" to="/employer/interview">
                          <span>({result.counts && result.counts.interview})</span> See All
                            </Link>
                      </div>

                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Job Title</th>
                            <th>Client</th>
                            <th>Date</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {result.upcommingInterview && result.upcommingInterview.length > 0 ? result.upcommingInterview.map((item, key) => (
                            <tr>
                              <td data-column="Candidate Name">
                                {item.candidate_name}
                              </td>
                              <td data-column="Job Title">
                                {item.applying_for}
                              </td>
                              <td data-column="Client">
                                {item.client}
                              </td>
                              <td data-column="Date">
                                {moment(item.interview_date).format(
                                  'Do MMMM YYYY'
                                )}
                              </td>
                              <td data-column="Time">
                                {moment(item.interview_date).format(
                                  'hh:mm A'
                                )}
                              </td>
                            </tr>
                          )) : <tr><td style={{ padding: '0px' }} colSpan="5">
                            <div className="no_record">
                              <div className="no_record_container">
                                <div className="tip_icon">
                                  <i className="fa fa-calendar-plus"></i>
                                </div>
                                <div className="interview_add_">
                                  <span>No upcoming interviews<br />Want to Schedule an Interview</span>

                                  <Link to='/employer/interview'><button class="no_interview">Click Here</button></Link>

                                </div>
                              </div>
                            </div>
                          </td></tr>}
                        </tbody>
                      </table>
                    </div>

                    {/* <!--end of interview secytion --> */}

                    {/* <div className="con_ur_ac_with">
                          <div className="conuracwith">
                            <h2>Connect Your Acoount With LinkedIn</h2>

                            <div className="internal_promotion_content">
                              <p>Promote Your Jobs In Your LinkedIn Network </p>

                              <Link className="styconn" To="">
                                <span>Connect With LinkedIn</span>
                              </Link>
                            </div>
                          </div>
                        </div> */}

                    {/* <!--end of promotion--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
    // )
    // );
  }
}

export default Dashboard;
