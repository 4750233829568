import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import { get } from './../../services/Apicall';
import * as Constant from './../../services/Constant';
import CourseCardVertical from './../../pages/common/CourseCardVertical';
import { notify } from 'react-notify-toast';

const $ = window.$;

class HomeCourseSection extends React.Component {

  constructor(){
    super();
    this.state={
      topCourses:[] 
    }
  }

	componentDidMount(){

}




render() {
  return (
    <>
          {/* course_list */}
          <div className="educrslistsec">
            <div className="container">
              <h4 className="edcrtile">Upgrade your Skills for better Opportunities <span>
                <a href={Constant._SITE_LEARNING_URL}>View All</a></span> </h4>
              <ul id="content-slider" className="content-slider">
                {
                  this.props.item.map( (i,key)=>{
                    if(key<4)
                  return <li className="crsedulist" >
                    <CourseCardVertical item={i} />
                  </li>
                  })
                }
              
              </ul>
            </div>
          </div>
          {/* end of course list */}
</>
  );
}

}
export default HomeCourseSection;
