import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { notify } from 'react-notify-toast';
import { connect } from 'react-redux'
import { get } from './../../services/Apicall';
import * as Helper from './../../services/Helper';

//import Header from './../../common/Header';;

const $ = window.$;

class Header_after_login extends React.Component {

  constructor() {
    super();
    this.state = { data: [] }
  }

  componentDidMount() {

    get('v2/emp/headerdata', {}, true).then((result) => {
      let responseJson = result;
      if (responseJson.data) {
        this.setState({
          isLoading: false,
          data: responseJson.data,
        });
        localStorage.setItem('emp_name', responseJson.data.name);
        localStorage.setItem('emp_company', responseJson.data.company);
        localStorage.setItem('emp_pid', responseJson.data.parent);

        localStorage.setItem('emp_total_download', responseJson.data.total_download);
        localStorage.setItem('emp_total_jobpost', responseJson.data.total_jobpost);
        localStorage.setItem('emp_used_download', responseJson.data.used_download);
        localStorage.setItem('emp_used_jobpost', responseJson.data.used_jobpost);
        localStorage.setItem('emp_plan_start', responseJson.data.plan_start);
        localStorage.setItem('emp_plan_end', responseJson.data.plan_end);
        localStorage.setItem('emp_last_login', responseJson.data.last_login);
        localStorage.setItem('emp_a_candidate_search', responseJson.data.candidate_search);
        localStorage.setItem('emp_a_candidate_view', responseJson.data.candidate_view);
        localStorage.setItem('emp_a_candidate_interview', responseJson.data.candidate_interview);
        localStorage.setItem('emp_a_job_view', responseJson.data.job_view);
        localStorage.setItem('emp_a_job_posting', responseJson.data.job_posting);
        localStorage.setItem('emp_a_update_job_status', responseJson.data.update_job_status);
        localStorage.setItem('emp_a_share_job', responseJson.data.share_job);
        localStorage.setItem('emp_a_preminum_search', responseJson.data.preminum_search);

      } else {
        this.setState({ isLoading: false });
        notify.show('Something went wrong while geting data!', 'warning');
      }
    });
  }


  logout = () => {
    localStorage.removeItem('emp_name');
    localStorage.removeItem('emp_company');
    localStorage.removeItem('emp_mobile');
    localStorage.removeItem('emp_email');
    localStorage.removeItem('emp_id');
    localStorage.removeItem('emp_pid');
    localStorage.removeItem('emp_token');
    localStorage.removeItem('emp_image');
    localStorage.removeItem('emp_logo');
    localStorage.removeItem('emp_imageurl');
    localStorage.removeItem('emp_total_download');
    localStorage.removeItem('emp_total_jobpost');
    localStorage.removeItem('emp_used_download');
    localStorage.removeItem('emp_used_jobpost');
    localStorage.removeItem('emp_plan_start');
    localStorage.removeItem('emp_plan_end');
    localStorage.removeItem('emp_last_login');
    localStorage.removeItem('emp_a_candidate_search');
    localStorage.removeItem('emp_a_candidate_view');
    localStorage.removeItem('emp_a_candidate_interview');
    localStorage.removeItem('emp_a_job_view');
    localStorage.removeItem('emp_a_job_posting');
    localStorage.removeItem('emp_a_update_job_status');
    localStorage.removeItem('emp_a_share_job');
    localStorage.removeItem('emp_a_preminum_search');

    notify.show('You have been Signed off...', 'success');
    //this.props.redirect(true);
    window.location.href = '/employer'
    // this.setState({redirectToReferrer: true});


  }
  render() {
    const plan_start = Date.parse(this.state.data.plan_start);
    const plan_end = Date.parse(this.state.data.plan_end);
    const today = new Date();
    const diffTime = Math.abs(plan_end - plan_start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return (
      <>
        <header id="header" className={(this.props.transparent) ? "main_header emp_main_header" : "main_header emp_main_header withtranclr"}>
          <nav className="navbar navbar-inverse navbar-fixed-top" id="employer" role="navigation">
            <div className="container header_container">
              <div className="navbar-header">
                <div className="dropdown mobile_only">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                    <span> {(localStorage.emp_logo) ?
                      <img src={localStorage.emp_imageurl + localStorage.emp_logo} style={{ width: 50 }} alt="veriipro" title="" /> :
                      // <img src="/../images/profile.png" style={{width:50}} alt="veriipro"  title="" />
                      <picture>
                        <source width="640" height="360" alt="profile" title="profile" style={{ width: 50 }} srcset="/../images/profile.webp" />
                        <source width="640" height="360" alt="profile" title="profile" style={{ width: 50 }} srcset="/../images/profile.png" />
                        <img src="/../images/profile.png" alt="profile" title="profile" style={{ width: 50 }} width="640" height="360" />
                      </picture>

                    }</span>
                    {/* <span className="drpdm_jbme">Me <i className="caret"></i></span> */}
                  </a>
                  <ul className="dropdown-menu dropdown-lr animated slideInRight" role="menu">
                    <div className="col-lg-12 drop_down_menu_bar">
                      <div className="mprsecnmempr">
                        <div className="proimgsec">
                          {(localStorage.emp_logo) ?
                            <img src={localStorage.emp_imageurl + localStorage.emp_logo} style={{ width: 50 }} alt="veriipro" title="" /> :
                            // <img src="/../images/profile.png" style={{width:50}} alt="veriipro"  title="" />
                            <picture>
                              <source width="640" height="360" alt="profile" title="profile" style={{ width: 50 }} srcset="/../images/profile.webp" />
                              <source width="640" height="360" alt="profile" title="profile" style={{ width: 50 }} srcset="/../images/profile.png" />
                              <img src="/../images/profile.png" alt="profile" title="profile" style={{ width: 50 }} width="640" height="360" />
                            </picture>
                          }
                        </div>
                        <div className="prodetsecne">
                          <p className="prnameprt"><Link target="_blank" to={!Helper.isEmpty(localStorage.emp_pid) ? "/company/" + localStorage.emp_pid : "/company/" + localStorage.emp_id} >{localStorage.getItem('emp_company')}</Link></p>
                          <p className="prnameprt"><Link target="_blank" to={"/recruiter/" + localStorage.emp_id}>{localStorage.getItem('emp_name')}</Link></p>
                          <p className="premailprt">{localStorage.getItem('emp_email')}</p>
                        </div>
                      </div>
                      <div className="text-center lstlogidetail background_wi_color"><h3>Last Login: {localStorage.getItem('emp_last_login') ? Helper.formatDateTime(localStorage.getItem('emp_last_login')) : 'NA'}</h3></div>
                      <div className="text-center"><h3><Link to="/employer/updateprofile">Edit Profile</Link></h3></div>
                      {(localStorage.emp_pid) ? <div className="text-center"><h3><Link to="/employer/update_company_profile">Update Company Profile</Link></h3></div> : null}
                      <div className="text-center background_wi_color">Account</div>
                      <div className="text-center"><h3 className="job_post_credit">Downloads : {localStorage.getItem('emp_used_download')} / {localStorage.getItem('emp_total_download')} Credit</h3></div>
                      <div className="text-center"><h3 className="job_post_credit">Job Posts : {localStorage.getItem('emp_used_jobpost')} / {localStorage.getItem('emp_total_jobpost')} Credit</h3></div>
                      <div className="text-center">
                          <h3> <Link to="/employer/chat"> View Messages </Link> </h3>
                      </div>
                      <div className="text-center">
                          <h3> <Link to="/employer/change_password"> Change Password </Link> </h3>
                      </div>

                      {/* <div className="text-center background_wi_color">Manage</div> */}
                      {(localStorage.emp_pid) ?
                        <> <div className="text-center">
                          <h3> <Link to="/employer/manage_user"> Manage Subuser </Link> </h3>
                        </div>
                          <div className="text-center">
                            <h3> <Link to="/employer/order_history"> Order History </Link> </h3>
                          </div>
                          <div className="text-center">
                            <h3> <Link to="/employer/allocation_history"> Allocation History </Link> </h3>
                          </div>
                          <div className="text-center">
                            <h3> <Link to="/employer/pricing"> Subscription Plan</Link> </h3>
                          </div>
                          <div className="text-center">
                            <h3> <Link to="/contact-us" target="_blank">Contact us</Link> </h3>
                          </div>
                        </> : null}
                      <div className="text-center"><h3><a href="#" onClick={this.logout}>Logout</a></h3></div>
                    </div>
                  </ul>
                </div>

                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <Link className="navbar-brand" to="/employer">
                  {/* <img className="main_logo_header" src="/images/Veriipro%20Logo-03.png" alt="veriipro"  title=""/> */}
                  <picture>
                    <source className="main_logo_header" width="640" height="360" alt="VeriiPro logo" title="VeriiPro Home" srcset="/images/Veriipro%20Logo-03.webp" />
                    <source className="main_logo_header" width="640" height="360" alt="VeriiPro logo" title="VeriiPro Home" srcset="/images/Veriipro%20Logo-03.png" />
                    <img className="main_logo_header" src="/images/Veriipro%20Logo-03.png" alt="VeriiPro logo" title="VeriiPro Home" width="640" height="360" />
                  </picture>
                </Link>
              </div>
              <div id="navbar" className="collapse navbar-collapse">
                <ul className="nav navbar-nav navbar-right">

                  <li>
                    <Link to="/employer/Dashboard">
                      <span>
                        {/* <img src="/../images/emp_deshboard.png" alt="veriipro"  title=""/> */}
                        <picture>
                          <source width="640" height="360" alt="Dashboard" title="Dashboard" srcset="/../images/emp_deshboard.webp" />
                          <source width="640" height="360" alt="Dashboard" title="Dashboard" srcset="/../images/emp_deshboard.png" />
                          <img src="/../images/emp_deshboard.png" alt="Dashboard" title="Dashboard" width="640" height="360" />
                        </picture>
                      </span>
                      <span>Dashboard</span>
                    </Link>
                  </li>

                  <li className="dropdown">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                      <span>
                        {/* <img src="/../images/candidate_search.png" alt="veriipro"  title=""/> */}
                        <picture>
                          <source width="640" height="360" alt="candidate_search" title="" srcset="/../images/candidate_search.webp" />
                          <source width="640" height="360" alt="candidate_search" title="" srcset="/../images/candidate_search.png" />
                          <img src="/../images/candidate_search.png" alt="candidate_search" title="" width="640" height="360" />
                        </picture>

                      </span>
                      <span className="drpdm_jbme">Candidate Search<i className="caret"></i></span>
                    </a>
                    <ul className="dropdown-menu dropdown-lr animated flipInX" role="menu">
                      <div className="col-lg-12 drop_down_menu_bar">
                        {localStorage.emp_a_preminum_search == '1'  ?
                          <div className="text-center">
                            <h3> <Link to="/employer/candidate_search"> Candidate Search  </Link> </h3>
                          </div>
                          : null}

                        {localStorage.emp_a_preminum_search == '1'  ? <div className="text-center">
                          <h3> <Link to="/employer/Premium_Job_post_listing"> Premium Request </Link> </h3>
                        </div>
                          : null}
                        <div className="text-center">
                          <h3> <Link to="/employer/Saved_candidate_history"> Saved Candidates </Link> </h3>
                        </div>
                        <div className="text-center">
                          <h3> <Link to="/employer/Candidate_download_history"> Downloaded Candidates </Link> </h3>
                        </div>
                        <div className="text-center">
                          <h3> <Link to="/employer/Tagged_candidate"> Tagged candidates </Link> </h3>
                        </div>
                      </div>
                    </ul>
                  </li>

                  {localStorage.emp_a_job_posting == '1' ?
                    <li>
                      <Link to="/employer/my_jobs">
                        <span>
                          {/* <img src="/../images/job.png" alt="veriipro"  title=""/> */}
                          <picture>
                            <source width="640" height="360" alt="job" title="" srcset="/../images/job.webp" />
                            <source width="640" height="360" alt="job" title="" srcset="/../images/job.png" />
                            <img src="/../images/job.png" alt="job" title="" width="640" height="360" />
                          </picture>

                        </span>
                        <span>Jobs</span>
                      </Link>
                    </li>
                    : null}

                  {localStorage.emp_a_candidate_interview == '1' ?
                    <li>
                      <Link to="/employer/interview"><span>
                        {/* <img src="/../images/interview.png" alt="veriipro"  title=""/> */}
                        <picture>
                          <source width="640" height="360" alt="interview" title="" srcset="/../images/interview.webp" />
                          <source width="640" height="360" alt="interview" title="" srcset="/../images/interview.png" />
                          <img src="/../images/interview.png" alt="interview" title="" width="640" height="360" />
                        </picture>
                      </span><span>Interview</span></Link>
                    </li>
                    : null
                  }
                  {/* <li>
            <Link to="/assesment"><span><img src="/../images/assess.png" alt="veriipro"  title=""/></span><span>Assessment</span></Link>
          </li>
          <li>
            <Link to=""><span><img src="/../images/report.png" alt="veriipro"  title=""/></span><span>Reports</span></Link>
          </li> */}
                  {/* <li className="dropdown">
            <Link to=""><span><img src="/../images/elearn.jpg" alt="veriipro"  title=""/></span><span>E-learning</span> </Link>
          </li> */}
                  <li className="dropdown desktop_only">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                      <span> {(localStorage.emp_logo) ?
                        <img src={localStorage.emp_imageurl + localStorage.emp_logo} style={{ width: 50 }} alt="veriipro" title="" /> :
                        // <img src="/../images/profile.png" style={{width:50}} alt="veriipro"  title="" />
                        <picture>
                          <source width="640" height="360" alt="profile" title="" style={{ width: 50 }} srcset="/../images/profile.webp" />
                          <source width="640" height="360" alt="profile" title="" style={{ width: 50 }} srcset="/../images/profile.png" />
                          <img src="/../images/profile.png" alt="profile" title="" style={{ width: 50 }} width="640" height="360" />
                        </picture>
                      }</span>
                      {/* <span className="drpdm_jbme">Me <i className="caret"></i></span> */}
                    </a>
                    <ul className="dropdown-menu dropdown-lr animated slideInRight" role="menu">
                      <div className="col-lg-12 drop_down_menu_bar">
                        <div className="mprsecnmempr">
                          <div className="proimgsec">
                            {(localStorage.emp_logo) ?
                              <img src={localStorage.emp_imageurl + localStorage.emp_logo} style={{ width: 50 }} alt="veriipro" title="" /> :
                              // <img src="/../images/profile.png" style={{width:50}} alt="veriipro"  title="" />
                              <picture>
                                <source width="640" height="360" alt="profile" title="" style={{ width: 50 }} srcset="/../images/profile.webp" />
                                <source width="640" height="360" alt="profile" title="" style={{ width: 50 }} srcset="/../images/profile.png" />
                                <img src="/../images/profile.png" alt="profile" title="" style={{ width: 50 }} width="640" height="360" />
                              </picture>

                            }
                          </div>
                          <div className="prodetsecne">
                            <p className="prnameprt"><Link target="_blank" to={!Helper.isEmpty(localStorage.emp_pid) ? "/company/" + localStorage.emp_pid : "/company/" + localStorage.emp_id} >{localStorage.getItem('emp_company')}</Link></p>
                            <p className="prnameprt"><Link target="_blank" to={"/recruiter/" + localStorage.emp_id}>{localStorage.getItem('emp_name')}</Link></p>
                            <p className="premailprt">{localStorage.getItem('emp_email')}</p>
                          </div>
                        </div>
                        <div className="text-center lstlogidetail background_wi_color"><h3>Last Login: {localStorage.getItem('emp_last_login') ? Helper.formatDateTime(localStorage.getItem('emp_last_login')) : 'NA'}</h3></div>
                        <div className="text-center"><h3><Link to="/employer/updateprofile">Edit Profile</Link></h3></div>
                        {(localStorage.emp_pid) ? <div className="text-center"><h3><Link to="/employer/update_company_profile">Update Company Profile</Link></h3></div> : null}
                        <div className="text-center background_wi_color">Account</div>
                        <div className="text-center menu_hlaf">
                          <h3 className="job_post_credit">
                            <span className="half_title">Downloads </span>
                            <span className="half_title"> {localStorage.getItem('emp_used_download')} / {localStorage.getItem('emp_total_download')}</span></h3></div>
                        <div className="text-center menu_hlaf">
                          <h3 className="job_post_credit">
                            <span className="half_title">Job Post </span>
                            <span className="half_title"> {localStorage.getItem('emp_used_jobpost')} / {localStorage.getItem('emp_total_jobpost')} </span></h3></div>
                        {/* <div className="text-center">
                  <h3> <Link to="/employer/Saved_candidate_history"> Saved Candidates </Link> </h3>
                </div>
                <div className="text-center">
                  <h3> <Link to="/employer/Candidate_download_history"> Downloaded Candidates </Link> </h3>
                </div> */}
                        {/* <div className="text-center background_wi_color">Manage</div> */}
                        <div className="text-center">
                          <h3> <Link to="/employer/chat"> View Messages </Link> </h3>
                        </div>
                        <div className="text-center">
                          <h3> <Link to="/employer/change_password"> Change Password </Link> </h3>
                        </div>
                        {(localStorage.emp_pid) ? <>
                          <div className="text-center">
                            <h3> <Link to="/employer/manage_user"> Manage Subuser </Link> </h3>
                          </div>
                          <div className="text-center">
                            <h3> <Link to="/employer/order_history"> Order History </Link> </h3>
                          </div>
                          <div className="text-center">
                            <h3> <Link to="/employer/allocation_history"> Allocation History </Link> </h3>
                          </div>
                          <div className="text-center">
                            <h3> <Link to="/employer/pricing"> Subscription Plan</Link> </h3>
                          </div>
                          <div className="text-center">
                            <h3> <Link to="/contact-us" target="_blank">Contact us</Link> </h3>
                          </div>
                        </> : null}
                        <div className="text-center"><h3><a href="#" onClick={this.logout}>Logout</a></h3></div>
                      </div>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

        </header>
        { (this.state.data.plan_end == '0000-00-00 00:00:00') ?
          <div class="marqueSect">
            <marquee>⚠ You don't have any Subscription, Please Subscribe to our plans and  unlock unlimited opportunities with our verified professionals community.</marquee>
          </div>
          :
          (plan_end < today) ?
            <div class="marqueSect">
              <marquee>⚠Your Subscription has expired, Please Subscribe to our plans and  unlock unlimited opportunities with our verified professionals community.</marquee>
            </div>
            :
            (diffDays <= 2) ?
              <div class="marqueSect">
                <marquee>⚠Your Subscription is going to expire on {Helper.formatDate(this.state.data.plan_end)}, Please Renew to ensure uninterrupted access to the Resume Database.</marquee>
              </div>
              :
              null}


      </>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    redirectToEHome: state.redirectToHome
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (value) => { dispatch({ type: 'CHANGE_REDIRECT2HOME', payload: value }) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header_after_login);
//export default Header_after_login;
