import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { get, put } from './../../services/Apicall';
import { notify } from 'react-notify-toast';
import * as Helper from './../../services/Helper';


const $ = window.$;

class RecruiterCard extends React.Component {

    constructor() {
        super();
        this.state = {
            redirectToSearch: false,

        }
    }

    componentDidMount() {

    }


    followRec = (rec_id, action) => {
        if (action == 'login') {
            var org = this.props.location.pathname;
            this.props.history.push('/jobseeker/login' + '?ref=' + org)
        }
        else
            put('v2/followrec', { action: action, rec_id: rec_id }).then((result) => {
                let responseJson = result;
                if (responseJson.error === false) {
                    this.props.followRec(rec_id, action)
                } else {
                    notify.show('Something Went Wrong!', 'error');
                }
            });
    }





    render() {
        let item = this.props.item
        return (

            <div class="singl__blo_ck_em_info">
                <div class="fu__lde__tail_sec">
                    <div class="recu__iter__detail">
                        <div class="rec__img">
                            <Link to={"/recruiter/" + item.employer_id}>
                                {(item.recruiter_profile_pic) ?
                                    <img src={item.imageurl + item.recruiter_profile_pic} alt="profile" title="" />
                                    :
                                    //   <img src="/../images/profile_.png" alt="veriipro" />
                                    <picture>
                                        <source width="640" height="360" alt="profile" title="" srcset="/images/profile_.webp" />
                                        <source width="640" height="360" alt="profile" title="" srcset="/images/profile_.png" />
                                        <img src="/images/profile_.png" alt="profile" title="" width="640" height="360" />
                                    </picture>
                                }
                            </Link>
                        </div>
                        <div class="rec__lalogin"><span><Link to={"/recruiter/" + item.employer_id}>{item.recruiter_name}</Link></span></div>
                        <div class="rec_posby lo_font"><span><Link to={item.parent_id ? "/company/" + item.parent_id : "/company/" + item.employer_id}>{item.company_name}</Link></span></div>
                        <div class="rec_poson lo_font"><span>{item.recruiter_designation}</span></div>
                        <div class="rec_posdesg lo_font"><span>Hiring for :</span></div>
                        <div class="rec_posdesg">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>Level</p>
                                        </td>
                                        <td><span>{item.recruiter_hiring_for_level}</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Industry</p>
                                        </td>
                                        <td><span>{item.recruiter_industry}</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>Job Title</p>
                                        </td>
                                        <td><span>{item.recruiter_hiring_job_tittle}</span></td>
                                    </tr>
                                    {/* <tr>
                        <td>
                           <p>Work-auth.</p>
                        </td>
                        <td><span>Green card , H1B</span></td>
                     </tr> */}
                                    <tr>
                                        <td>
                                            <p>Skills</p>
                                        </td>
                                        <td><span>{item.recruiter_hiring_skill}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="fol__rec_sec">
                            <div class="fol__rec_link follow_me">
                                <button> <span class=""> {item.follower} Followers  </span> </button>

                                {
                                    (localStorage.ud_token) ?
                                        (this.props.item.follow) ?
                                            <span class="foll__oee__btn" onClick={() => this.followRec(this.props.item.employer_id, 'unfollow')}> <i class="fa fa-plus" aria-hidden="true"> <span>Un follow</span> </i> </span>
                                            :
                                            <span class="foll__oee__btn" onClick={() => this.followRec(this.props.item.employer_id, 'follow')}> <i class="fa fa-plus" aria-hidden="true"> <span>Follow</span> </i> </span>
                                        :
                                        <span class="foll__oee__btn" title='Login to Follow Recruiter' onClick={() => this.followRec(this.props.item.employer_id, 'login')}> <i class="fa fa-plus" aria-hidden="true"> <span>Follow</span> </i> </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default RecruiterCard;
