import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import * as Constant from '../../services/Constant'
//import Header from './../../common/Header';;

const $ = window.$;

class Footer extends React.Component {

  constructor() {
    super();
    this.state = {}
  }

  componentDidMount() {

    window.scrollTo(0, 0);

  }

  openLink = (link, data = null) => {
    window.scrollTo(0, 0);
    this.props.history.push({
      pathname: link,
      state: data, // your data array of objects
    });
  }


  render() {
    return (
      <>
        {/* <!-- FOOTER --> */}
        { (!localStorage.getItem('ud_token') && ! /employer/.test(window.location.pathname) && ! /search/.test(window.location.pathname) && ! /jobs\//.test(window.location.pathname)) ?
          <>
            <div className="top-footer">
              <div class="above_footer">

                {/* <div class="above_footer-inner col-lg-4 col-md-4 col-sm-6 col-xs-12">
<p>Quick Links</p>
<ul>
<li> <Link to="">Browse Jobs</Link> </li>
<li> <Link to="">Submit Resume</Link> </li>
<li> <Link to="">About Veriipro</Link> </li>
<li> <Link to="">Privacy Policy</Link> </li>
<li> <Link to="">Terms & Conditions</Link> </li>
<li> <Link to="">Contact us</Link> </li>
<li> <Link to="">Veriipro Blog</Link> </li>
</ul>
</div> */}
                <div class="above_footer-inner col-lg-4 col-md-4 col-sm-6 col-xs-12">
                  <p>Jobs In Popular States</p>
                  <ul>
                    <li> <Link to="/jobs/jobs-in-delhi">Jobs in Delhi</Link> </li>
                    <li> <Link to="/jobs/jobs-in-maharashtra"> Jobs in Maharashtra</Link> </li>
                    <li> <Link to="/jobs/jobs-in-haryana">Jobs in Haryana</Link> </li>
                    <li> <Link to="/jobs/jobs-in-karnataka"> Jobs in Karnataka</Link> </li>
                    <li> <Link to="/jobs/jobs-in-telangana"> Jobs in Telangana</Link> </li>
                    <li> <Link to="/jobs/jobs-in-chandigarh"> Jobs in Chandigarh</Link> </li>
                    <li> <Link to="/jobs/jobs-in-tamil-nadu">Jobs in Tamil Nadu</Link> </li>
                  </ul>
                </div>
                <div class="above_footer-inner col-lg-4 col-md-4 col-sm-6 col-xs-12">
                  <p>Jobs In Popular Cities</p>
                  <ul>
                    <li> <Link to="/jobs/jobs-in-noida">Jobs in Noida</Link> </li>
                    <li> <Link to="/jobs/jobs-in-mumbai"> Jobs in Mumbai</Link> </li>
                    <li> <Link to="/jobs/jobs-in-delhi">Jobs in Delhi</Link> </li>
                    <li> <Link to="/jobs/jobs-in-kolkata"> Jobs in Kolkata</Link> </li>
                    <li> <Link to="/jobs/jobs-in-bangalore"> Jobs in Bangalore</Link> </li>
                    <li> <Link to="/jobs/jobs-in-hyderabad">Jobs in Hyderabad</Link> </li>
                    <li> <Link to="/jobs/jobs-in-los-gurgaon"> Jobs in Gurgaon</Link> </li>
                  </ul>
                </div>
                <div class="above_footer-inner col-lg-4 col-md-4 col-sm-6 col-xs-12">
                  <p>Other Jobs</p>
                  <ul>
                    <li> <Link to="/jobs/information-technology-jobs">IT Jobs</Link> </li>
                    <li> <Link to="/jobs/contract-jobs">Contract Jobs</Link> </li>
                    <li> <Link to="/jobs/salesforce-jobs">Salesforce Jobs</Link> </li>
                    <li> <Link to="/jobs/network-jobs">Network Jobs</Link> </li>
                    <li> <Link to="/jobs/software-jobs">Software Jobs</Link> </li>
                    <li> <Link to="/jobs/java-jobs">Java Developer Jobs</Link> </li>
                    <li> <Link to="/jobs/project-manager-jobs">Project Manager Jobs</Link> </li>
                  </ul>
                </div>
              </div>

            </div>


            <div className="footer-top">

              <div className="container-fluid">

                <ul className="footer-col ">

                  <p>VeriiPro services</p>
                  {/* <li><a href='javaScript:void(0)' onClick={()=>this.openLink('/')}>JobSeeker Login</a></li>   */}
                  <li><Link to='/employer'>Employer Login</Link></li>
                  <li><a href='javaScript:void(0)' onClick={() => this.openLink('/')}>Job Search</a></li>
                  {/* <li><Link to='/employer/candidate_search'>Candidate Search</Link></li> */}
                  {/* <li><a href='javaScript:void(0)' onClick={()=>this.openLink('/','skillss')}>Jobs by Skills</a></li>
                    <li><a href='javaScript:void(0)' onClick={()=>this.openLink('/','location')}>Jobs by Location</a></li>
                    <li><a href='javaScript:void(0)' onClick={()=>this.openLink('/','popularsearch')}>Jobs by  Popular Search</a></li> */}
                  <li><a href='/jobs#skills' >Jobs by Skills</a></li>
                  <li><a href='/jobs#Locations' >Jobs by Location</a></li>
                  <li><a href='/jobs#popular_search' >Jobs by  Popular Search</a></li>

                </ul>
                <ul className="footer-col">

                  <p>About VeriiPro</p>

                  <li><Link to="/about-us">About Us</Link></li>
                  <li><a href="https://www.veriipro.com/blog/">Blog</a></li>
                  <li><Link to="/contact-us">Contact Us</Link></li>
                  <li><Link to='/privacy-policy'>Privacy policy</Link></li>
                  <li><Link to='/terms-of-use'>Terms of Use</Link></li>

                </ul>

                <div className="footer-col social_icon_section">
                  <ul>
                    <p>Connect</p>
                    <a href={Constant._SITE_SOCIAL_LINKEDIN} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-linkedin"></i></span></a>
                    <a href={Constant._SITE_SOCIAL_TWITTER} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-twitter"></i></span></a>
                    <a href={Constant._SITE_SOCIAL_INSTAGRAM} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-instagram"></i></span></a>
                    <a href={Constant._SITE_SOCIAL_FACEBOOK} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-facebook"></i></span></a>
                    <a href={Constant._SITE_SOCIAL_PINTEREST} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-pinterest-p"></i></span></a>
                    <a href={Constant._SITE_SOCIAL_TUMBLR} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-tumblr"></i></span></a>
                    {/* <a href={Constant._SITE_SOCIAL_REDDIT} target="_blank"><span><i style={{fontSize:25}} className="fab fa-reddit"></i></span></a> */}


                  </ul>

                  <div class="appStore">
                    <ul>
                      <li><a href={Constant._SITE_PLAY_STORE_URL} target='_blank' rel="noreferrer">
                        {/* <img src="/images/ico_andr1.png" /> */}
                        <picture>
                          <source width="640" height="360" alt="Playstore" title="Download VeriiPro Mobile app for Android from Playstore" srcset="/images/ico_andr1.webp" />
                          <source width="640" height="360" alt="Playstore" title="Download VeriiPro Mobile app for Android from Playstore" srcset="/images/ico_andr1.png" />
                          <img src="/images/ico_andr1.png" alt="Playstore" title="Download VeriiPro Mobile app for Android from Playstore" width="640" height="360" />
                        </picture>
                      </a></li>
                      <li><a href={Constant._SITE_APP_STORE_URL} target='_blank' rel="noreferrer">
                        {/* <img src="/images/ico_gpa_1.png" /> */}
                        <picture>
                          <source width="640" height="360" alt="AppStore" title="Download VeriiPro Mobile app for Android from Appstore" srcset="/images/ico_gpa_1.webp" />
                          <source width="640" height="360" alt="AppStore" title="Download VeriiPro Mobile app for Android from Appstore" srcset="/images/ico_gpa_1.png" />
                          <img src="/images/ico_gpa_1.png" alt="AppStore" title="Download VeriiPro Mobile app for Android from Appstore" width="640" height="360" />
                        </picture>
                      </a></li>
                    </ul>
                  </div>
                </div>



              </div>

            </div>
          </> : <div className="footer-top onlyconnect">

            <div className="container-fluid">
              <div className="footer-col social_icon_section">
                <ul className="onlyconnect_block">
                  <div>
                    <li><Link to="/about-us">About Us</Link></li>
                    <li><a href="https://www.veriipro.com/blog/">Blog</a></li>
                    {/* <li><Link to="/contact-us">Contact Us</Link></li> */}
                  </div>
                  <div>
                    <li><Link to='/privacy-policy'>Privacy policy</Link></li>
                    <li><Link to='/terms-of-use'>Terms of Use</Link></li>
                  </div>
                </ul>
                <ul className="onlyconnect_block">
                  <p>Connect</p>
                  <a href={Constant._SITE_SOCIAL_LINKEDIN} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-linkedin"></i></span></a>
                  <a href={Constant._SITE_SOCIAL_TWITTER} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-twitter"></i></span></a>
                  <a href={Constant._SITE_SOCIAL_INSTAGRAM} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-instagram"></i></span></a>
                  <a href={Constant._SITE_SOCIAL_FACEBOOK} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-facebook"></i></span></a>
                  <a href={Constant._SITE_SOCIAL_PINTEREST} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-pinterest-p"></i></span></a>
                  <a href={Constant._SITE_SOCIAL_TUMBLR} target="_blank" rel="noreferrer"><span><i style={{ fontSize: 25 }} className="fab fa-tumblr"></i></span></a>
                  {/* <a href={Constant._SITE_SOCIAL_REDDIT} target="_blank"><span><i style={{fontSize:25}} className="fab fa-reddit"></i></span></a> */}
                </ul>
                <div class="appStore">
                  <ul>
                    <li><a href={Constant._SITE_PLAY_STORE_URL} target='_blank' rel="noreferrer">
                      {/* <img src="/images/ico_andr1.png" /> */}
                      <picture>
                        <source width="640" height="360" alt="Playstore" title="Download VeriiPro Mobile app for Android from Playstore" srcset="/images/ico_andr1.webp" />
                        <source width="640" height="360" alt="Playstore" title="Download VeriiPro Mobile app for Android from Playstore" srcset="/images/ico_andr1.png" />
                        <img src="/images/ico_andr1.png" alt="Playstore" title="Download VeriiPro Mobile app for Android from Playstore" width="640" height="360" />
                      </picture>
                    </a></li>
                    <li><a href={Constant._SITE_APP_STORE_URL} target='_blank' rel="noreferrer">
                      {/* <img src="/images/ico_gpa_1.png" /> */}
                      <picture>
                        <source width="640" height="360" alt="AppStore" title="Download VeriiPro Mobile app for Android from Appstore" srcset="/images/ico_gpa_1.webp" />
                        <source width="640" height="360" alt="AppStore" title="Download VeriiPro Mobile app for Android from Appstore" srcset="/images/ico_gpa_1.png" />
                        <img src="/images/ico_gpa_1.png" alt="AppStore" title="Download VeriiPro Mobile app for Android from Appstore" width="640" height="360" />
                      </picture>
                    </a></li>
                  </ul>
                </div>
              </div>



            </div>

          </div>
        }
        {/* <!-- END FOOTER -->

afterlogin login page for app share */}



        {/* <!-- MAIN-FOOTER -->  */}

        <div className="footer-bottom">

          <div className="container-fluid">

            <div className=" col-lg-6 col-md-6 cpy_rgh">
              <span className="ftrtxt">VeriiPro © 2022</span>
            </div>

            <div className=" col-lg-3 col-md-3 ftr mob_mail">
              <div className="icon_based" style={{ paddingTop: 3 }}>
                <a href={'tel:' + Constant._SITE_CONTACT_PHONE}><i style={{ fontSize: 15, transform: "rotate(110deg)", paddingLeft: "5px" }} className="fa fa-phone" aria-hidden="true">
                </i>
                  <div className="text_base">  {Constant._SITE_CONTACT_PHONE}  </div></a></div>

              <div className="icon_based"> <a href={'mailto: ' + Constant._SITE_CONTACT_EMAIL}> <i style={{ fontSize: 15, padding: "8px 0px" }} className="fa fa-envelope"></i> <div style={{ paddingTop: 5 }} className="text_base">{Constant._SITE_CONTACT_EMAIL} </div></a></div>

            </div>

            <div className=" col-lg-3 col-md-3 fologo">
              <span>
                <Link to='/'>
                  {/* <img src="/images/Veriipro%20Logo-03.png" alt="Veriipro" /> */}
                  <picture>
                    <source className="main_logo_header" width="640" height="360" srcset="/images/Veriipro%20Logo-03.webp" />
                    <source className="main_logo_header" width="640" height="360" srcset="/images/Veriipro%20Logo-03.png" />
                    <img className="main_logo_header" src="/images/Veriipro%20Logo-03.png" alt="Veriipro Logo" width="640" height="360" />
                  </picture>
                </Link>
              </span>
            </div>

          </div>

        </div>

        {/* <!-- END MAIN-FOOTER --> */}

      </>
    );
  }

}
export default withRouter(Footer);
