import React from 'react';
import { render } from 'react-dom';
//import Hello from './Hello';

import Scroll from 'react-scroll';
import '../employer/scroll.css';

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;
const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
};

class Section extends React.Component {
  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {
    Events.scrollEvent.register('begin', function () {
      //console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function () {
      //console.log('end', arguments);
    });

    scrollSpy.update();
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  render() {
    return (
      <div>
        <div
          style={{
            positio: 'absolute',
            left: 0,
            top: 0,
            height: '100vh',
            width: '80%',
            border: '#000 solid,2px',
            overflow: 'scroll',
            background: 'wheat',
          }}
        >
          <Link
            activeClass="active"
            to="test1"
            spy={true}
            smooth={true}
            duration={250}
            containerId="containerElement"
            style={{ display: 'inline-block', margin: '20px' }}
          >
            Test1
          </Link>
          <Link
            activeClass="active"
            to="test2"
            spy={true}
            smooth={true}
            duration={250}
            containerId="containerElement"
            style={{ display: 'inline-block', margin: '20px' }}
          >
            test2
          </Link>
          <Link
            activeClass="active"
            to="test3"
            spy={true}
            smooth={true}
            duration={250}
            containerId="containerElement"
            style={{ display: 'inline-block', margin: '20px' }}
          >
            test3
          </Link>

          <Link
            activeClass="active"
            to="test4"
            spy={true}
            smooth={true}
            duration={250}
            containerId="containerElement"
            style={{ display: 'inline-block', margin: '20px' }}
          >
            test4
          </Link>
          <Element
            name="test7"
            className="element"
            id="containerElement"
            style={{
              position: 'relative',
              height: '400px',
              overflow: 'scroll',
              marginBottom: '100px',
            }}
          >
            <Element
              name="test1"
              style={{
                marginBottom: '300px',
              }}
            >
              first element inside container
            </Element>
            <Element
              name="test2"
              style={{
                marginBottom: '300px',
              }}
            >
              second element inside container
            </Element>
            <Element
              name="test3"
              style={{
                marginBottom: '300px',
              }}
            >
              second element inside container
            </Element>
            <Element
              name="test4"
              style={{
                marginBottom: '300px',
              }}
            >
              second element inside container
            </Element>
          </Element>

          <Element id="same" className="element">
            Two links point to this
          </Element>

          <a onClick={this.scrollToTop}>To the top!</a>
        </div>
      </div>
    );
  }
}

export default Section;
