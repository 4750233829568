import React, { Component } from 'react';
import {Link} from 'react-router-dom'

const $ = window.$;
class Loader extends React.Component {
  constructor(){
    super();
  }
render() {
  return (
    <>

<div id="veriiproLoader" style={{display:'none'}}>
<div className="loader_overlay">
<div className="main__loader">
</div>
</div>
</div>


    </>
  );
}
}





/* Unmount React component */
function hide() {
  let target = document.getElementById('veriiproLoader').style.display = "none";
//  ReactDOM.unmountComponentAtNode(target);
}


/**
* Show Loader
*/
function show() {
  let target = document.getElementById('veriiproLoader').style.display = "block";

 // return false;
}


/* Export loader functions */
export let loader = {
  show,
  hide
};

export default Loader;
