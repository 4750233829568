import React from "react";
import { css } from "@emotion/core";
import HashLoader from "react-spinners/HashLoader";
 
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #ffc800;
  position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 99;
`;
 
class AwesomeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
 
  render() {
    return (
      <div className="sweet-loading">
        <HashLoader
          css={override}
          size={60}
          color={"#34495e"}
          loading={this.props.pageLoader}
        />
      </div>
    );
  }
}

export default AwesomeComponent;