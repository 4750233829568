import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { get } from './../../services/Apicall';
import * as Helper from './../../services/Helper';
import { notify } from 'react-notify-toast';
import axios from 'axios';


const $ = window.$;

class HomeAdviceSection extends React.Component {

  constructor() {
    super();
    this.state = {
      careerAdviceBlog: [],
      expertAdviceBlog: [],
      active: true,
      buttonIdsArray: ["nav-home-tab", "nav-profile-tab"]
    }
  }

  componentDidMount() {

    var urlExpertAdviceBlog = 'https://veriipro.com/blog/wp-json/wp/v2/posts?categories=13&per_page=3&order=desc';
    var urlCareerAdviceBlog = 'https://veriipro.com/blog/wp-json/wp/v2/posts?categories=4&per_page=3&order=desc';

    axios.get(urlExpertAdviceBlog, {})
      .then((result) => {
        var data = result.data;
        this.setState({ expertAdviceBlog: data })
      })
      .catch(function (error) {
        let errorObject = JSON.parse(JSON.stringify(error));
        let op = "";
        if (errorObject.message != undefined)
          op = errorObject.message;

        if (errorObject.message == undefined)
          notify.show(errorObject.response ? op : "No connection");
        else
          notify.show(errorObject.message, 'error');
      });
    axios.get(urlCareerAdviceBlog, {})
      .then((result) => {
        var data = result.data;
        this.setState({ careerAdviceBlog: data })
      })
      .catch(function (error) {
        let errorObject = JSON.parse(JSON.stringify(error));
        let op = "";
        if (errorObject.message != undefined)
          op = errorObject.message;

        if (errorObject.message == undefined)
          notify.show(errorObject.response ? op : "No connection");
        else
          notify.show(errorObject.message, 'error');
      });
    this.initButton();

  }

  initButton = () => {
    this.state.buttonIdsArray.forEach(a => {
      document.getElementById(a).classList.remove("active");
      document.getElementById(a).classList.add("inactive");
      document.getElementById('nav-home-tab').classList.add("main_active");
      document.getElementById('nav-profile-tab').classList.remove("inactive");
    });
  };
  handleClick = id => {
    this.initButton();
    document.getElementById(id).classList.add("active");
    document.getElementById(id).classList.remove("inactive");
    document.getElementById('nav-home-tab').classList.remove("main_active");
    this.setState({ active: !this.state.active });
  };


  render() {
    const { active } = this.state.active;
    //console.log(active);
    return (
      <>
        {/* carrer advice */}

        {/* <!-- Tabs --> */}
        <section id="tabs" className="carrer_expert_advice">
          <div className="container">
            <div className="row cead">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
                <nav>
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a onClick={() => this.handleClick("nav-home-tab")} className={active ? "btn-animation" : "nav-item nav-link main_active"} id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="  ">Career Advice</a>
                    <a onClick={() => this.handleClick("nav-profile-tab")} className={active ? "btn-animation" : "nav-item nav-link"} id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Expert Advice</a>
                  </div>
                </nav>
                <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                    {this.state.careerAdviceBlog.map((item, key) => {
                      return <div className="single-post ">
                        <div className="thumb carexpcrd">
                          <img src={item.fimg_url} alt={item.title.rendered} />
                          <div className="meta justify-content-between d-flex">
                            <span> {Helper.formatDate(item.date)}</span>
                            <span className="carexcrdsp">
                              <span className="lnr lnr-heart"></span>
                              06
                              <span className="lnr lnr-bubble"></span>
                              02
                            </span>
                          </div>
                        </div>
                        <div className="details carexcrdts">
                          <div className="titlejustify-content-between">
                            <div className="titles">
                              <a href={item.link} target='_blank'><h4>{item.title.rendered}</h4></a>
                            </div>
                          </div>
                        </div>
                      </div>

                    })
                    }

                  </div>
                  <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">

                    {this.state.expertAdviceBlog.map((item, key) => {
                      return <div className="single-post ">
                        <div className="thumb carexpcrd">
                          <img src={item.fimg_url} alt={item.title.rendered} />
                          <div className="meta justify-content-between d-flex">
                            <span> {Helper.formatDate(item.date)}</span>
                            <span className="carexcrdsp">
                              <span className="lnr lnr-heart"></span>
                              06
                              <span className="lnr lnr-bubble"></span>
                              02
                            </span>
                          </div>
                        </div>
                        <div className="details carexcrdts">
                          <div className="titlejustify-content-between">
                            <div className="titles">
                              <a href={item.link} target='_blank'><h4>{item.title.rendered}</h4></a>
                            </div>
                          </div>
                        </div>
                      </div>

                    })
                    }

                  </div>

                </div>

              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 sidebar">



                <div className="single-slidebar">
                  <a href="https://www.veriipro.com/blog/job-seeker/get-yourself-ready-with-job-hunting-tips/" target="_blank">
                    <div className="blog-list">
                      <div className="single-blog ">
                        {/* <img src="/../images/reedit.jpg" alt="veriipro"  /> */}
                        <picture>
                          <source width="640" height="360" alt="Job search Tips" title="Job search Tips" srcset="/images/reedit.webp" />
                          <source width="640" height="360" alt="Job search Tips" title="Job search Tips" srcset="/images/reedit.png" />
                          <img src="/images/reedit.jpg" alt="Job search Tips" title="Job search Tips" width="640" height="360" />
                        </picture>
                      </div>
                      <div className="single_doen_block">
                        {/* <a href="single.html" ><h4>Create a free resume</h4></a> */}
                        <a href="https://www.veriipro.com/blog/job-seeker/get-yourself-ready-with-job-hunting-tips/" target="_blank"><h4>Job Search Tips</h4></a>
                      </div>


                    </div></a>
                </div>
              </div>


            </div>
          </div>
        </section>

        {/* end of carrer advice */}

      </>
    );
  }

}
export default HomeAdviceSection;
