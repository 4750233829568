import dateFormat from 'dateformat';
//import moment from 'react-moment'

import * as moment from 'moment';



export function formatTime(date) {
    if(!date)
        return '';
	try{		
		return dateFormat(date, "hh:MM TT") 
	} catch (e) {
		 return '';
	}
}

export function formatDate(date) {
    if(!date || !moment(date,'YYYY-MM-DD').isValid() )
        return '';
	try{	
    return dateFormat(date, "mmmm dS, yyyy") ;
	} catch (e) {
		 return '';
	}
}

export function formatDateTime(date) {
   //console.log('--------')
   //console.log(date)
    if(!date  ||  !moment(date,'YYYY-MM-DD').isValid() )
        return '';
	try{	
    return dateFormat(date, "mmmm dS, yyyy hh:MM TT");
	} catch (e) {
		 return '';
	}
}

export function formatMonthYear(date) {
    if(!date)
        return '';
	try{	
    var d=date.split('-')
    return dateFormat(d[1]+'-'+d[0]+'-20', "mmm yyyy");
	} catch (e) {
		 return '';
	}
}

export function formatDP2DB(date) {
    if(!date)
        return '';
    const newLocal = new Date(date);
    return moment(newLocal).format("MM-YYYY");
}

export function formatDB2DP(date) {
    
    if(!date || date=='current' || !moment(date,'MM-YYYY').isValid() )
        return '';
   var d=date.split('-')
    return  new Date(d[1]+'-'+d[0]+'-01');
}

export function formatDP2DB2(date) {
    if(!date)
        return '';
    const newLocal = new Date(date);
    return moment(newLocal).format("YYYY-MM-DD");
}

export function formatDB2DP2(date) {
    
    if(!date || !moment(date,'YYYY-MM-DD').isValid() )
        return '';
   var d=date.split('-')
    return  new Date(d[0]+'-'+d[1]+'-'+d[2]);
}


export function buildSearchUrl(jobtitle,location,exp) {
    let uri='';
    if(jobtitle)
        uri=jobtitle.replace(' ','-')
    if(jobtitle && location)
        uri+='-jobs-in-';
    else if(jobtitle && !location)
        uri+='-jobs';
    else if(!jobtitle && location)
        uri+='jobs-in-';
    if(location)
        uri+=location.replace(',',' ').replace(' ','-')
    if( (jobtitle || location) && exp )
        uri+='-experience-';
    if(exp)
        uri+=exp.replace(' ','-')+'-years'
    
    return uri.replace(/[^a-zA-Z0-9,\-\.\: ]/g, "");
}

export function getQueryFromUrl(uri) {
    var jt,jl,exp;
     jt=jl=exp='';
     uri=decodeURIComponent(uri);

    if(uri.includes('-jobs'))
    {
        jt=uri.split('-jobs')[0].replace(/-/g,' ').replace(/[^a-zA-Z0-9, :\.]/g, "");
    }
    if(uri.includes('jobs-in-'))
    {
        jl=uri.split('jobs-in-')[1].split('-experience-')[0].replace(/-/g,' ').replace(/[^a-zA-Z0-9, ]/g, "");
    }
    if(uri.includes('-experience-'))
    {
        exp =uri.split('-experience-')[1].split('-years')[0].replace(/-/g,' ').replace(/[^a-zA-Z0-9, ]/g, "");
    }
    
        
        
       
    // var part1,part2,part3,part4; 
    //     part1= uri.split("-jobs");
    //     jt=part1[0];
    //     // part1= uri.match(/(.*)-+jobs/);
    //     // jt=part1[1];
        
    //     part2= uri.split("jobs-in-");
    //     if(part2[1])
    //     part3= part2[1].split("-experience-")
    //     // part2= uri.match(/.*jobs-in-(.*)(-experience-)?/);
    //     // jl=part2[1];alert(part2)
    //     // part3= uri.match(/experience-(\d{1,2})-years/);
    //     // exp=part3[1];

    //     jl=part3[0];
    //     part4=part3[1].split('-years');
    //     exp=part4[0]
        
        var arr= new Array();
        arr['job_title'] = jt;
        arr['location'] = jl;
        arr['experience'] = exp;
    return arr;
}


export function url(url){

    if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
    }
    return url;
}
export function numberWithCommas(x) {
	if(!x)
		return '';
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export function toTitleCase(toTransform) {
    if(isEmpty(toTransform))
        return '';
    else
    return toTransform.replace(/\b([a-z])/g, function (_, initial) {
        return initial.toUpperCase();
    });
  }
export function convertCamelCaseToString(strCamelCase) {
    return strCamelCase.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })
}

export const isEmpty = (str) => {
    str = (str === undefined || str == null || str === "0") ? "" : str.toString().trim();
    if (str === "")
        return true;
    return false;
}

export const strTruncate = (str, strLen = 200) => {
    return str && str.length > strLen ? str.substring(0, strLen) + '...' : str;
}






function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

