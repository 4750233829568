import React from 'react';
import { Link ,withRouter} from 'react-router-dom'
import Rating from 'react-rating';
import * as Constant from './../../services/Constant' ;

class CourseCardVertical extends React.Component {

    constructor() {
        super();
        this.state = {}
    }
 

    render() {
        var item = this.props.item;
        return (



          <div className="content">
          <div className="img_withoverlay">
          {/* <img src={(item.series_image) ? item.image_url + item.series_image : "/images/cloud.jpg"}   alt={item.series_title} /> */}
          <picture>
                <source width="640" height="360"  alt={item.series_title} srcset={(item.series_image) ? item.image_url + item.series_image : "/images/cloud.webp"}/>
                <source width="640" height="360"  alt={item.series_title} srcset={(item.series_image) ? item.image_url + item.series_image : "/images/cloud.jpg"}/>
                <img  src={(item.series_image) ? item.image_url + item.series_image : "/images/cloud.jpg"} alt={item.series_title} width="640" height="360"/>
              </picture> 
          </div>
          {/* <!-- copy this stuff and down --> */}
          <div className="course_caed_detail_box">
          <a href={Constant._SITE_LEARNING_URL+"course/" + item.series_id} target="_blank">
            <h4>{item.series_title}</h4>
          </a>
          <a href={Constant._SITE_LEARNING_URL+"/instructor/" + item.series_trainer_id} target="_blank"><p className="tutor">{item.series_trainer} </p></a>
          <div className="rating">
            <span className="st_ar">
            <Rating emptySymbol="far fa-star " fullSymbol="fa fa-star" readonly="true"  fractions={2} initialRating={item.series_rating+'5'}/>
            </span>

            <span className="rate">0{item.series_rating}</span>
            <span className="total">(0{item.series_ratedby})</span>
            <span className="rate card_save_sec" style={{display:'none'}}><span className="far fa-heart pull-right" title="Login to Save this Course"></span></span>
          </div>
          <div className="priceing">
            {/* <s> <span className="real">₹ 1034</span></s> */}
            <span className="offer">{(item.series_type=='PAID')? '$ '+item.series_price : 'FREE '}</span>
          </div>
          </div>
        </div>
     

        

        );
    }
}

export default withRouter (CourseCardVertical);
