import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags';
import Header from './../common/Header'
import Footer from './../common/Footer'
import AdviceSection from './../common/HomeAdviceSection'
import CourseSection from './../common/HomeCourseSection'
import { get, post, DeviceId, DeviceType } from './../../services/Apicall';
import { notify } from 'react-notify-toast';
import ReCAPTCHA from "react-google-recaptcha";
import Slider from "react-slick";
import * as Constant from './../../services/Constant'
import axios from 'axios';
import $ from 'jquery';
import ReactGA from 'react-ga';

const popularsearch = ['Developer', 'DevOps', 'Data Scientist', 'Python', 'Hadoop', 'Elasticsearch', 'Amazon Web Services', 'AngularJS', 'Oracle', 'Spring', 'Cloud', 'Java', 'Cyber security', 'MySQL', 'iOS Developer', 'Business Analyst', 'Scrum Master', 'Machine Learning', 'Network Administrator', 'SAP', 'Game Developer', 'Kotlin', '.NET', 'Tableau', 'Perl', 'Ruby', 'Spark'];

class Index extends React.Component {

  constructor() {
    super();
    this.myRef = React.createRef();
    this.state = {
      email: '',
      password: '',
      'g-recaptcha-response': '',
      deviceid: DeviceId(),
      devicetype: DeviceType(),
      redirectToReferrer: false,
      redirectToProfile: false,
      otp: '',
      eyeicon: false,
      userid: '',
      data: {
        topRoles: [],
        topLocations: [],
        topIndustries: [],
        topCompanies: [[]],
        topSkills: [[]],
        topCourses: []

      },
      activeRole: 0,

    };

  }


  componentWillMount() {
    /*  const dataToscroll = this.props.location.state;
      
      alert(dataToscroll) 
      if(dataToscroll){
        var pos=$("#"+dataToscroll).scrollTop()
        window.scrollTo(0, pos);
       // $('.nav-tabs a[href="#' + dataToscroll + '"]').tab('show');
       // $('[href="#'+dataToscroll+'"]').tab('show');
      }*/

  }
  componentDidMount() {
    //console.log('Trigger .... Page view GA-- ' + window.location.pathname + window.location.search);
    // This line will trigger on a route change
    ReactGA.initialize('UA-151243375-1');
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);

    window.onscroll = function () {
      var header = document.getElementById("header");
      if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
        if (header)
          header.classList.add("scrol_down");
      } else {
        if (header)
          header.classList.remove("scrol_down");
      }
    }

    get('v2/homepage', {}).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        this.setState({ data: responseJson.data });
      } else if (responseJson.error === true) {
        notify.show('Something went wrong while loading data.', 'error');
      }
    });



  }

  validateLoginForm = () => {
    var regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regexEmail.test(String(this.state.email).toLowerCase())) {
      notify.show("Enter a valid email", 'error'); return false;
    } else if (!this.state.password || this.state.password.length < 8) {
      notify.show("Enter a valid password", 'error'); return false;
    } else { return true; }
  }
  login = (e) => {
    e.preventDefault();
    if (this.validateLoginForm()) {

      post('v2/login', this.state).then((result) => {
        let responseJson = result;

        if (responseJson.data && responseJson.error === false) {
          if (responseJson.data.isProfileComplete === false)
            this.setState({ redirectToProfile: true });
          localStorage.setItem('ud_fname', responseJson.data.first_name);
          if (responseJson.data.last_name == null)
            localStorage.setItem('ud_lname', '');
          else
            localStorage.setItem('ud_lname', responseJson.data.last_name);
          localStorage.setItem('ud_mobile', responseJson.data.phone);
          localStorage.setItem('ud_email', responseJson.data.email);
          localStorage.setItem('ud_tid', responseJson.data.training_id);
          localStorage.setItem('ud_id', responseJson.data.id);
          if (responseJson.data.image == null)
            localStorage.setItem('ud_image', '');
          else
            localStorage.setItem('ud_image', responseJson.data.image);
          localStorage.setItem('ud_imageurl', responseJson.imageurl);
          localStorage.setItem('ud_token', responseJson.access_token);
          //window.location.reload(false);
          //this.props.history.push('/')
          this.setState({ redirectToReferrer: true });
        } else if (responseJson.error === true && responseJson.uid) {
          notify.show('Please Verify Your Account', 'warning');
          this.setState({ userid: responseJson.uid });

        } else if (responseJson.error === true) {
          notify.show(responseJson.msg, 'error');

        }

      });
      window.grecaptcha.reset();

    }

  }
  handleTogle = () => {
    this.setState({ eyeicon: !this.state.eyeicon });
  };
  verifyaccount = (e) => {
    e.preventDefault();
    if (this.state.otp && this.state.userid) {
      post('v2/signupotpverify', this.state).then((result) => {
        let responseJson = result;
        if (responseJson.data && responseJson.error === false) {

          if (responseJson.data.isProfileComplete)
            this.setState({ redirectToProfile: true });

          localStorage.setItem('ud_fname', responseJson.data.first_name);
          if (responseJson.data.last_name == null)
            localStorage.setItem('ud_lname', '');
          else
            localStorage.setItem('ud_lname', responseJson.data.last_name);
          localStorage.setItem('ud_mobile', responseJson.data.phone);
          localStorage.setItem('ud_email', responseJson.data.email);
          localStorage.setItem('ud_tid', responseJson.data.training_id);
          localStorage.setItem('ud_id', responseJson.data.id);
          if (responseJson.data.image == null)
            localStorage.setItem('ud_image', '');
          else
            localStorage.setItem('ud_image', responseJson.data.image);
          localStorage.setItem('ud_imageurl', responseJson.imageurl);
          localStorage.setItem('ud_token', responseJson.access_token);
          this.setState({ redirectToReferrer: true });
          notify.show('Welcome to the Network of Professionals', 'success');
        } else if (responseJson.error === true) {
          notify.show(responseJson.msg, 'error');

        }

      });
    } else {
      notify.show('Enter a valid OTP', 'error');
    }

  }

  resendOtp = (e) => {
    e.preventDefault();
    if (this.state.userid) {
      post('v2/resendsignupotp', { user_id: this.state.userid }).then((result) => {
        let responseJson = result;
        if (responseJson.msg && responseJson.error === false) {
          notify.show('OTP sent to your Registered Email ID ' + this.state.email, 'success');
        } else if (responseJson.error === true) {
          notify.show(responseJson.msg, 'error');

        }

      });
    }

  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }



  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };

    if (this.state.redirectToReferrer || localStorage.getItem('ud_token')) {
      if (this.props.location.search) {
        var qs = this.props.location.search.split('?ref=');
        var ref = qs[1];
        if (ref != undefined)
          window.location.href = ref;
        else
          window.location.href = '/';
      } else if (this.state.redirectToProfile)
        window.location.href = '/updateprofile';
      else
        window.location.href = '/'
      //return (<Redirect to={'/'} />)
      //return (<Redirect to={window.location.pathname} />)
    }
    var longSkillArray = this.state.data.topSkills[this.state.activeRole];
    var smallerSkillArrays = []; // will contain the sub-arrays of 9 elements each
    var chunkSize = 9;
    longSkillArray = Object.entries(longSkillArray);
    for (var i = 0; i < Math.ceil(longSkillArray.length / chunkSize); i++) {
      smallerSkillArrays.push(longSkillArray.slice(i * chunkSize, i * chunkSize + chunkSize));
    }
    var longCompanyArray = this.state.data.topCompanies[this.state.activeRole];
    var smallerCompanyArrays = []; // will contain the sub-arrays of 6 elements each
    var chunkSize = 12;
    longCompanyArray = Object.entries(longCompanyArray);
    for (var i = 0; i < Math.ceil(longCompanyArray.length / chunkSize); i++) {
      smallerCompanyArrays.push(longCompanyArray.slice(i * chunkSize, i * chunkSize + chunkSize));
    }
    return (
      <>
        <MetaTags>
          <title>Online Job Board | Verified Professional Jobs in the USA - Veriipro Jobs</title>
          <meta name="title" content="Online Job Board | Verified Professional Jobs in the USA - Veriipro Jobs" />
          {/* <!-- Search Engine --> */}
          <meta name="description" content="The best online career advice and job searching website for IT professionals in the USA. You can find all kinds of job listings online with no hassle and no cost." />
          <meta name="keywords" content="best job search sites in USA, professional jobs in USA, professional jobs in USA" />
          <meta name="image" content="https://www.veriipro.com/images/123.png" />

          <link rel="canonical" href="https://www.veriipro.com/" />

          {/* <!-- Schema.org for Google --> */}
          {/* <meta itemprop="name" content="Online Job Board | Verified Professional Jobs in the USA - Veriipro Jobs" />
          <meta itemprop="description" content="The best online career advice and job searching website for IT professionals in the USA. You can find all kinds of job listings online with no hassle and no cost." />
          <meta itemprop="image" content="https://www.veriipro.com/images/123.png" /> */}

          <meta property="og:title" content="Online Job Board | Verified Professional Jobs in the USA - Veriipro Jobs" />
          <meta property="og:description" content="The best online career advice and job searching website for IT professionals in the USA. You can find all kinds of job listings online with no hassle and no cost." />
          <meta property="og:image" content="https://www.veriipro.com/assets_front/images/veriipro_app_icon.png" />


        </MetaTags>

        <div className="main-body main-ho with_out_login">

          <Header transparent={true} home={true} />

          {/* banner */}
          <div className="main_banner_top_header">

            <section className="banner-area" id="home">
              <div className="overlay overlay-bg"></div>
              <div className="container">
                <div className="row fullscreen d-flex align-items-center justify-content-center">
                  <div className="banner-content col-lg-12">
                    <div className="container" id="login-container_left">
                      <h1>Own Your Opportunity <br /> Elevate Your Career</h1>
                    </div>
                    <div className="container" id="login-container">
                      <div className="main-container-inner">

                        <div className="container-form">  <form onSubmit={this.login} autocomplete="new-password" className="ban_login_form" style={(this.state.userid === '') ? { display: 'block' } : { display: 'none' }}>

                          <h3 className="login_form_h3">Job Seeker Login</h3>
                          <input type="email" maxLength={40} name="email" onChange={this.onChange} className="input-email input" id="email" placeholder="Email" maxLength='80' autocomplete="new-password" />
                          <br />
                          <p className="errors" id="emailError"></p>
                          <div className="password_eye emp">
                            <input type={this.state.eyeicon ? 'text' : 'password'} maxLength={20} name="password" onChange={this.onChange} className="input-password input" id="large-password" placeholder="Password" autocomplete="new-password" />
                            {this.state.eyeicon ? (
                              <span class="fa fa-eye-slash hideshow_pass" aria-hidden="true" onClick={this.handleTogle}></span>
                            ) : (
                                <span
                                  class="fa fa-eye hideshow_pass"
                                  aria-hidden="true"
                                  onClick={this.handleTogle}
                                ></span>
                              )}
                          </div>
                          <div className="col-lg-12 form-group recaptcha_style">

                            <ReCAPTCHA ref={e => (this.captcha = e)} sitekey={Constant._SITE_RECAPTCHA_SITE_KEY} onChange={(value) => { this.setState({ 'g-recaptcha-response': value }) }} />
                          </div>
                          <div className="message-display">
                            <p id="submit-message"></p>
                          </div>
                          <button type="submit" className="button-submit"  >Login</button>
                        </form>  <form onSubmit={this.verifyaccount} autocomplete="off" className="ban_login_form" style={(this.state.userid != '') ? { display: 'block' } : { display: 'none' }}>
                            <h3 className="login_form_h3">Jobseeker Login</h3>
                            <input type="text" maxLength={6} name="otp" onChange={this.onChange} required className="input-email input" id="otp" placeholder="Enter OTP sent on registered email" maxLength='6' autocomplete="new-password" />
                            <br />
                            <div className="message-display">
                              <p id="submit-message"></p>
                            </div>
                            <button type="submit" className="button-submit"  >Verify</button>
                            <div className="col-lg-12 login-btm forgot_pass_word">
                              <a href="#" onClick={this.resendOtp} className="">Resend OTP</a>
                            </div>  </form></div>
                        <div className="forgot-password main-container-footer" id="forgotPasswordLarge">
                          <p><Link to="/jobseeker/forgotten-password" className="forgot-password_link" id="forgot-password_sec">Forgot Password?</Link></p>
                        </div>
                        <div className="main-container-footer">
                          <p >New to VeriiPro?<Link to="/jobseeker/registration" className="sign-up" id="sign-up"> Sign up now!</Link></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* end of banner */}

          {/* find job by */}

          {/* new section */}

          <div className="candiate_info_block">

            <div className="candidate_info_block_inner">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 inn_left_section">
                <p className="inn_left_txt">Join Community of Verified Professionals</p>
                <span className="inn_left_txt_span">Let us help you to get best Job Opportunities</span>
                <span className="inn_left_txt_span">Get free access to the hot job offers</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 inn_right_section">
                {/* <img className="cand_info_img" src="/images/123.png" width="640" height="360" /> */}
                <picture>
                  <source className="cand_info_img" alt="Join Community of Verified Professionals" title="" width="640" height="360" srcset="/images/123.webp" />
                  <source className="cand_info_img" alt="Join Community of Verified Professionals" title="" width="640" height="360" srcset="/images/123.png" />
                  <img className="cand_info_img" src="/images/123.png" alt="Join Community of Verified Professionals" title="" width="640" height="360" />
                </picture>
              </div>
            </div>
            <div className="candidate_info_block_inner">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 inn_right_section">
                {/* <img className="cand_info_img" src="/images/2nd.png" width="640" height="360" /> */}
                <picture>
                  <source className="cand_info_img" alt="Find a Career that brings out your best" title="" width="640" height="360" srcset="/images/2nd.webp" />
                  <source className="cand_info_img" alt="Find a Career that brings out your best" title="" width="640" height="360" srcset="/images/2nd.png" />
                  <img className="cand_info_img" src="/images/2nd.png" alt="Find a Career that brings out your best" title="" width="640" height="360" />
                </picture>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 inn_left_section">
                <p className="inn_left_txt">Find a Career that brings out your best.</p>
                <span className="inn_left_txt_span">With VeriiPro Career Portal</span>
                <span className="inn_left_txt_span">you can meet your career goals.</span>
              </div>
            </div>
            <div className="candidate_info_block_inner">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 inn_left_section">
                <p className="inn_left_txt">Chase for your next Career Goal </p>
                <span className="inn_left_txt_span">let us help you to get best Job Opportunities</span>
                <span className="inn_left_txt_span">Get free access to the hot job offers</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 inn_right_section">
                {/* <img className="cand_info_img" src="/images/chase.jpeg" width="640" height="360" /> */}
                <picture>
                  <source className="cand_info_img" width="640" height="360" alt="Chase for your next Career Goal" title="" srcset="/images/chase.webp" />
                  <source className="cand_info_img" width="640" height="360" alt="Chase for your next Career Goal" title="" srcset="/images/chase.jpeg" />
                  <img className="cand_info_img" src="/images/chase.jpeg" alt="Chase for your next Career Goal" title="" width="640" height="360" />
                </picture>
              </div>
            </div>

          </div>



          {/* end of new section */}

          {/* <!--filters--> */}
          <div id="Locations"></div>
          <div id="popular_search" ref={this.myRef}></div>
          <div className="grayBox" id="skills">

            <div className="container parentslider" id="skill">

              <div className="homeTabSect">
                <h4>Find Job By</h4>
                <ul className="nav nav-pills hometabList">
                  <li className="active"><a href="#skillss" data-toggle="tab"> Skill</a></li>
                  <li><a href="#location" data-toggle="tab"> Location</a></li>
                  {/*<!--<li><a href="#industy" data-toggle="tab"> Industry</a></li>-->*/}
                  <li><a href="#popularsearch" data-toggle="tab"> Popular Search</a></li>
                </ul>
                <div className="tab-content clearfix">
                  <div className="tab-pane active" id="skillss">
                    <div className="skillSect">

                      <Slider {...settings}>
                        {
                          this.state.data.topRoles.map((item, key) => {
                            return <div className={(this.state.activeRole == key) ? "skillBx active" : "skillBx"} onMouseOver={() => this.setState({ 'activeRole': key })}>
                              <Link to={'/jobs/' + encodeURIComponent(item.role_name.replace(/[\s\\\/]+/g, '-').toLowerCase()) + '-jobs'}>
                                {item.role_name}
                              </Link>
                            </div>
                          })
                        }
                      </Slider>

                      <div className="hoverContent">
                        <div className="hoverContentLeft">
                          <ul>{
                            (smallerSkillArrays.length > 0) ?
                              smallerSkillArrays[0].map((item, key) => {
                                item = item[1]
                                {/* <!-- box start --> */ }
                                return <li>
                                  <div className="technoBox">
                                    <Link to={'/jobs/' + encodeURIComponent(item.skill_name.replace(/[\s\\\/]+/g, '-').toLowerCase()) + '-jobs'} title={item.skill_name}>
                                      {item.skill_name}
                                    </Link>
                                  </div>
                                </li>
                              }) : null
                          }
                          </ul>
                        </div>
                        <div className="hoverContentRight">
                          <div className="companyList">
                            <ul>  {
                              (smallerCompanyArrays.length > 0) ?
                                smallerCompanyArrays[0].map((item, key) => {
                                  item = item[1]
                                  // console.log(item)
                                  return <li>
                                    <div className="companyBoxLogo">
                                      <Link to={'/search/employer:' + encodeURI(item.company_name) + '-jobs'}>
                                        {
                                          (item.company_image) ?
                                            <img src="/../images/amazon.png" alt={item.company_name} title={item.company_name} />
                                            :
                                            <span>{item.company_name}</span>}
                                      </Link>
                                    </div>
                                  </li>
                                }) : null
                            }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="location">
                    <div className="locatio__name_vy_vty_ste">
                      <ul>
                        {
                          this.state.data.topLocations.map((item, key) => {
                            return <li key={key}><Link to={'/jobs/jobs-in-' + encodeURIComponent((item.primary_city + '-' + item.state).replace(/[\s\\\/]+/g, '-').toLowerCase())}>{item.primary_city + ', ' + item.state}</Link></li>
                          })
                        }
                      </ul>
                    </div>
                  </div>

                  {/* <div className="tab-pane" id="industy">
                <div className="locatio__name_vy_vty_ste industry___all__sho_of industyList">
                    <ul>
                      {
                        this.state.data.topIndustries.map((item, key) => {
                          return <li key={key}><Link to={'/search/' +encodeURIComponent(item.r_industry) + '-jobs'}>{item.r_industry}</Link></li>
                        })
                      }
                    </ul>
                    </div>
				</div>  */}

                  <div className="tab-pane" id="popularsearch">
                    <div className="locatio__name_vy_vty_ste industry___all__sho_of industyList">
                      <ul>
                        {
                          popularsearch.map((item, key) => {
                            return <li key={key}><Link to={'/search/' + encodeURIComponent(item.replace(/[\s\\\/]+/g, '-').toLowerCase()) + '-jobs'}>{item}</Link></li>
                          })
                        }
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/* end of find job by */}
          <AdviceSection />
          {/*(this.state.data.topCourses.length) ?
            <CourseSection item={this.state.data.topCourses} /> : null*/}
          <Footer />
        </div>
      </>
    );
  }

}
export default Index;
