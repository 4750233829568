import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { notify } from 'react-notify-toast';
import * as Helper from './../../services/Helper';



const $ = window.$;

class Emp_header extends React.Component {

  constructor() {
    super();
    this.state = {
      redirectToSearch: false,
      job_title: '',
      loc_name: '',
      experience: ''
    }
  }

  componentDidMount() {

  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  searchJob = (e) => {
    e.preventDefault();
    if (this.state.job_title != '' || this.state.loc_name != '')
      this.setState({ redirectToSearch: true });
    else
      notify.show('Please Enter Keyword to Search', 'error');

  }
  buildexpOptions() {
    var arr = [];

    for (let i = 1; i <= 40; i++) {
      arr.push(<option value={i}>{i} Year{(i != 1) ? 's' : null} </option>)
    }

    return arr;
  }

  render() {

    if (this.state.redirectToSearch) {
      this.setState({ redirectToSearch: false });
      return (<Redirect to={'/search/' + Helper.buildSearchUrl(this.state.job_title, this.state.loc_name, this.state.experience)} />)
    }


    return (
      <>
        <header id="header" className="main_header emp_header">
          <div className="container header_container">
            <div className="mai__hedr">
              <div id="logo">
                <Link to="/employer">
                  {/* <img className="main_logo_header" width="640" height="360" src="https://www.veriipro.com/assets_front/images/Veriipro%20Logo-03.png" alt="Veriipro Jobs" title="Veriipro Jobs" /> */}
                  <picture>
                    <source className="main_logo_header" width="640" height="360" alt="veriipro Logo" title="VeriiPro Home" srcset="/images/Veriipro%20Logo-03.webp" />
                    <source className="main_logo_header" width="640" height="360" alt="veriipro Logo" title="VeriiPro Home" srcset="/images/Veriipro%20Logo-03.png" />
                    <img className="main_logo_header" src="/images/Veriipro%20Logo-03.png" alt="veriipro Logo" title="VeriiPro Home" width="640" height="360" />
                  </picture>
                </Link>
              </div>
              <nav id="nav-menu-container" className="emp_header_memu_outer">
                <ul className="nav-menu sf-js-enabled sf- emp_header_memu">

                  {/* <li className="emp_candi_home">
                  <Link to="/employer/candidate_search_request" >Candidate Search</Link>
                  </li> */}
                  <li className="emp_candi_home">
                    <Link to="/" >Jobseeker Login</Link>
                  </li>

                </ul>
              </nav>

            </div>
          </div>
        </header>

      </>
    );
  }

}
export default Emp_header;
