import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
// import Login from './../employerlogin/Login'
// import Sign_up from '../employerlogin/Signup'
import { get } from './../../services/Apicall';
import { notify } from 'react-notify-toast';
import axios from 'axios';
import * as Helper from './../../services/Helper';
import Autocomplete from './../common/Autocomplete'


const $ = window.$;

class Header extends React.Component {

  constructor() {
    super();
    this.state = {
      redirectToSearch: false,
      job_title: '',
      loc_name: '',
      experience: '',
      showMenu: false,
      showBlock: false,
      isTop: true
    }
    this.addClass = this.addClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
    this.showMenu = this.showMenu.bind(this);
    // this.showBlock = this.showBlock.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    // this.closeBlock = this.closeBlock.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }
  addClass() {
    this.setState({
      showBlock: true
    });
  }

  removeClass() {
    this.setState({
      showBlock: false
    });
  }

  showMenu(event, id, hideOnExternalClick = true) {
    event.preventDefault();

    this.setState({ showMenu: id }, () => {
      if (hideOnExternalClick)
        document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
    });
  }
  onScroll(isTop) {
    this.setState({ isTop });
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  setAutoComplete = (value, name) => {
    this.setState({ [name]: value });
  }
  searchJob = (e) => {
    e.preventDefault();
    if (this.state.job_title != '' || this.state.loc_name != '')
      this.setState({ redirectToSearch: true });
    else
      notify.show('Please Enter Keyword to Search', 'error');

  }
  buildexpOptions() {
    var arr = [];

    for (let i = 1; i <= 40; i++) {
      arr.push(<option value={i}>{i} Year{(i != 1) ? 's' : null} </option>)
    }

    return arr;
  }



  render() {

    if (this.state.redirectToSearch) {
      this.setState({ redirectToSearch: false });
      return (<Redirect to={'/search/' + Helper.buildSearchUrl(this.state.job_title, this.state.loc_name, this.state.experience)} />)
    }


    return (
      <>
        <header id="header" className={(this.props.transparent) ? "main_header" : "main_header scrol_down2"}>
          {/* Scroll {this.state.isTop ? 'down' : 'up'} */}
          <div className="container header_container">
            <div className="mai__hedr">
              <div id="logo">
                <Link to="/">
                  {/* <img className="main_logo_header" src="/images/Veriipro%20Logo-03.png" width="640" height="360" alt="Veriipro Jobs" title="Veriipro Jobs" /> */}
                  <picture>
                    <source className="main_logo_header" width="640" height="360" alt="VeriiPro Logo" title="VeriiPro Home" srcset="/images/Veriipro%20Logo-03.webp" />
                    <source className="main_logo_header" width="640" height="360" alt="VeriiPro logo" title="VeriiPro Home" srcset="/images/Veriipro%20Logo-03.png" />
                    <img className="main_logo_header" src="/images/Veriipro%20Logo-03.png" alt="VeriiPro logo" title="VeriiPro Home" width="640" height="360" />
                  </picture>
                </Link>

              </div>
              <div className="sml_src_empl_login">

                <div className="emp__lgin">
                  <Link to='/employer' className="ticker-btn" title="Employer Login">Employer Login</Link>
                </div>

              </div>
              <nav id="nav-menu-container">
                <ul className="nav-menu sf-js-enabled sf-arrows">
                  {(this.props.nosearch !== true) ?
                    <li className="mni_ser_sec no_mob_srn">
                      <div className="justify-content-center form-wrap">
                        <form action="#" onSubmit={this.searchJob} >
                          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-cols main_search">
                            <Autocomplete url="v2/getskill" className="form-control" placeholder="Skills, Job Title, Company" initValue={this.state.job_title} setAutoComplete={(v) => this.setAutoComplete(v, 'job_title')} />
                            {/* <input type="text" className="form-control" name="job_title" placeholder="Skills, Job Title, Company" value={this.state.jobTitle} onChange={this.onChange} /> */}
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 form-cols main_search" >
                            <Autocomplete url="v2/getonlylocation" className="form-control" placeholder="City, State" initValue={this.state.loc_name} setAutoComplete={(v) => this.setAutoComplete(v, 'loc_name')} />
                            {/* <input type="text" className="form-control" name="loc_name" placeholder="City, State" value={this.state.loc_name} onChange={this.onChange} /> */}

                          </div>

                          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 form-cols main_search" >
                            <select className="form-control" name='experience' value={this.state.experience} onChange={this.onChange} >
                              <option value=''>Experience</option>
                              <option value="0">Fresher</option>
                              {this.buildexpOptions()}

                            </select>
                            {/* <div className="dropdown">

                  <button id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Experience
                    <span className="caret"></span>
                  </button>
                    <ul className="dropdown-menu" aria-labelledby="dLabel">
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                      <li>5</li>
                      <li>5+</li>
                    </ul>

                      </div> */}
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 form-cols header_search_btn" >
                            <button type="submit" className="btn btn-info">
                              <span className="serch_btn">
                                Find Jobs
                    </span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </li> : null
                  }

                  <li className="emp__lgin">
                    <Link to='/employer' className="ticker-btn" title="Employer Login">Employer</Link>
                  </li>
                  {
                    (this.props.home != true) ?
                      <li className="emp__lgin">
                        <Link to='/jobseeker/login' className="ticker-btn" title="Jobseeker Login" >Job Seeker</Link>
                      </li> : null
                  }
                  {
                    this.state.showMenu != 50
                      ? (
                        <>
                          <li className="sim_seh_af_cl_serc_ope">
                            <input onClick={(e) => this.showMenu(e, '50', false)} className="expendable_input_box" type="text" />
                            <i className="serh_btn_sml_src fa fa-search"><span> Find job</span></i>

                          </li>
                        </>
                      )
                      : (
                        null
                      )
                  }

                </ul>
              </nav>

            </div>
          </div>
          {/* <Login /> */}
          {
            this.state.showMenu == 50
              ? (
                <>
                  <div className="aft_cli_ful_show" id="expendable">

                    <div onClick={(e) => this.closeMenu()} className="creooo">
                      <i className="fa fa-times"></i>
                    </div>
                    <div className="mni_ser_sec no_mob_srn1">
                      <div className="justify-content-center form-wrap">
                        <form action="#" onSubmit={this.searchJob} >
                          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 form-cols main_search">
                            <Autocomplete url="v2/getskill" className="form-control" placeholder="Skills, Job Title, Company" initValue={this.state.job_title} setAutoComplete={(v) => this.setAutoComplete(v, 'job_title')} />
                            {/* <input type="text" className="form-control" name="job_title" placeholder="Skills, Job Title, Company" value={this.state.jobTitle} onChange={this.onChange} /> */}
                          </div>
                          <div className="col-lg-3 col-md-5 col-sm-6 col-xs-12 form-cols header_dropdown" >
                            <Autocomplete url="v2/getonlylocation" className="form-control" placeholder="City, State" initValue={this.state.loc_name} setAutoComplete={(v) => this.setAutoComplete(v, 'loc_name')} required={false} />
                            {/* <input type="text" className="form-control" name="loc_name" placeholder="City, State" value={this.state.loc_name} onChange={this.onChange} /> */}
                            {/* <div className="dropdown">
                  <button id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Location
                    <span className="caret"></span>
                  </button>
                    <ul className="dropdown-menu" aria-labelledby="dLabel">
                      <li>Noida</li>
                      <li>Delhi</li>
                      <li>Calefonia</li>
                      <li>USA</li>
                      <li>India</li>
                    </ul>
                      </div> */}
                          </div>
                          <div className="col-lg-3 col-md-5 col-sm-6 col-xs-12 form-cols header_dropdown" >
                            <select className="form-control" name='experience' value={this.state.experience} onChange={this.onChange} >
                              <option value=''>Experience</option>
                              <option value="0">Fresher</option>
                              {this.buildexpOptions()}

                            </select>
                            {/* <div className="dropdown">
                  <button id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Experience
                    <span className="caret"></span>
                  </button>
                    <ul className="dropdown-menu" aria-labelledby="dLabel">
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                      <li>5</li>
                      <li>5+</li>
                    </ul>
                      </div> */}
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 form-cols header_search_btn" >
                            <button type="submit" className="btn btn-info">
                              <span className="serch_btn">
                                Find Jobs
                    </span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              )
              : (
                null
              )
          }
        </header>

      </>
    );
  }

}
export default Header;
