export const _SITE_CONTACT_PHONE = '+91-99-1111-3160';
export const _SITE_CONTACT_EMAIL = 'info@in.veriipro.com';
export const _SITE_CONTACT_ADDRESS_LINE1 = 'A-44, A Block';
export const _SITE_CONTACT_ADDRESS_LINE2 = 'Sector 16';
export const _SITE_CONTACT_ADDRESS_CITY = 'Noida';
export const _SITE_CONTACT_ADDRESS_STATE = 'UP';
export const _SITE_CONTACT_ADDRESS_ZIP = '201301';
export const _SITE_CONTACT_ADDRESS_COUNTRY = 'India';
export const _SITE_CONTACT_ADDRESS_FULL = 'A-44, A Block, Sector-16, Noida, UP 201301';
export const _SITE_COMPANY_NAME = 'VeriiPro Technologies, LLC.';
export const _SITE_SOCIAL_FACEBOOK = 'https://fb.me/veriipro';
export const _SITE_SOCIAL_LINKEDIN = 'https://www.linkedin.com/company/veriipro';
export const _SITE_SOCIAL_TWITTER = 'https://twitter.com/veriipro';
export const _SITE_SOCIAL_INSTAGRAM = 'https://www.instagram.com/veriipro/';
export const _SITE_SOCIAL_PINTEREST = 'https://www.pinterest.com/veriipro/';
export const _SITE_SOCIAL_TUMBLR = 'https://veriipro.tumblr.com/';
export const _SITE_SOCIAL_REDDIT = 'https://www.reddit.com/user/veriipro';
export const _SITE_JOB_URL = 'https://www.veriipro.com/';
export const _SITE_JOB_EMPLOYER_URL = 'https://www.veriipro.com/employer/';

export const _SITE_APP_STORE_URL = 'https://apps.apple.com/us/app/veriipro-job-search/id1479504997';
export const _SITE_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.veriipro.jobsearch';

export const _SITE_RECAPTCHA_SITE_KEY = '6LfSwJUUAAAAADcsebwzTBURqwA8tcHL7GuuPkId';
export const _SITE_LEARNING_URL = 'https://learning.veriipro.com/';


export const _MASTER_EDUCATION_LEVEL = ['Secondary Education or High School','Vocational Qualification','Undergraduate','Certificate / Diploma','Bachelor\'s Degree','Master\'s Degree','Doctorate or Professional Degree'];
export const _MASTER_COURSE_TYPE = ['Full Time','Part Time','Online', 'Distance or Correspondence','Executive Program','Certification'];
