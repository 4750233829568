import React, { Component } from 'react';
import {Route } from "react-router-dom";



import Login from './../pages/home/Index';
import Home from './../pages/home/Homepage'; 

import Emp_homepage from './../pages/home/Emp_homepage';
import Dashboard from './../pages/employer/Dashboard'; 

export class PrivateRoute extends Component {

    render() {
      if (localStorage.getItem('ud_token'))
        return (<Route exact path={this.props.path} component={this.props.component} />);
      else
        // return (<Redirect to={{ pathname: '/login', state: { from: props.location } }}  />);
        return (<Route exact path={this.props.path} component={Login} />);
    }
  }
  
 export  class LoginRoute extends Component {
  
    render() {
      if (!localStorage.getItem('ud_token'))
        return (<Route exact path={this.props.path} component={this.props.component} />);
      else
        return (<Route exact path={this.props.path} component={Home} />);
    }
  }

export class EmpPrivateRoute extends Component {

    render() {
      if (localStorage.getItem('emp_token'))
        return (<Route exact path={this.props.path} component={this.props.component} />);
      else
        // return (<Redirect to={{ pathname: '/login', state: { from: props.location } }}  />);
        return (<Route exact path={this.props.path} component={Emp_homepage} />);
    }
  }
  
 export  class EmpLoginRoute extends Component {
  
    render() {
      if (!localStorage.getItem('emp_token'))
        return (<Route exact path={this.props.path} component={this.props.component} />);
      else
        return (<Route exact path={this.props.path} component={Dashboard} />);
    }
  }