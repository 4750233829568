import React, { Component } from 'react';
import Job_list_section from './../common/job_list_section'
import AdviceSection from './../common/HomeAdviceSection'
import CourseSection from './../common/HomeCourseSection'
import RecruiterCard from './../common/RecruiterCard'
import Autocomplete from './../common/Autocomplete'
import Candidate_Header_after_login from './../common/Candidate_Header_after_login'
import Footer from './../common/Footer'
import { get } from './../../services/Apicall';
import { notify } from 'react-notify-toast';
import * as Helper from './../../services/Helper';
import { loader } from './../../services/Loader/Loader';
import { Link, Redirect } from 'react-router-dom'
import MetaTags from 'react-meta-tags';

//import { LocationData, renderLocationTitle } from './../../services/LocationData';
//import Autocomplete from 'react-autocomplete';

const $ = window.$;

class Homepage extends React.Component {

   constructor() {
      super();
      this.state = {
         redirectToReferrer: false,
         redirectToSearch: false,
         job_title: '',
         loc_name: '',
         experience: '',
         data: {
            recommendedJobs: [],
            topCourses: [],
            topRecruiter: []
         }


      };
   }

   componentDidMount() {
      loader.show();
      get('v2/homepage2', {}).then((result) => {
         loader.hide();
         let responseJson = result;
         if (responseJson.data && responseJson.error === false) {
            this.setState({ data: responseJson.data });
         } else if (responseJson.error === true) {
            notify.show('Something went wrong while loading data.', 'error');
         }
      });

   }


   onChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
   }

   setAutoComplete = (value, name) => {
      this.setState({ [name]: value });
   }

   searchJob = (e) => {
      e.preventDefault();
      if (this.state.job_title != '' || this.state.loc_name != '')
         this.setState({ redirectToSearch: true });
      else
         notify.show('Please Enter Keyword to Search', 'error');

   }
   buildexpOptions() {
      var arr = [];
      for (let i = 1; i <= 40; i++) {
         arr.push(<option value={i}>{i} Year{(i != 1) ? 's' : null} </option>)
      }
      return arr;
   }


   saveJob = (job_id, action) => {
      var data = this.state.data

      var index = data.recommendedJobs.findIndex(x => x.id === job_id);
      if (action == 'remove')
         data['recommendedJobs'][index]['saved'] = 0;
      else if (action == 'add')
         data['recommendedJobs'][index]['saved'] = 1;
      this.setState({ data })
   }

   followRec = (rec_id, action) => {
      var data = this.state.data

      var index = data.topRecruiter.findIndex(x => x.employer_id === rec_id);
      if (action == 'unfollow') {
         if (data['topRecruiter'][index]['follow'] == 1)
            data['topRecruiter'][index]['follower']--;
         data['topRecruiter'][index]['follow'] = 0;
      }
      else if (action == 'follow') {
         if (data['topRecruiter'][index]['follow'] == 0)
            data['topRecruiter'][index]['follower']++;
         data['topRecruiter'][index]['follow'] = 1;
      }
      this.setState({ data })
   }

   likeJob = (job_id, action) => {
      var data = this.state.data

      var index = data.recommendedJobs.findIndex(x => x.id === job_id);
      if (action == 'unlike') {
         data['recommendedJobs'][index]['liked'] = 0;
         data['recommendedJobs'][index]['likeCount']--;
      }
      else if (action == 'like') {
         data['recommendedJobs'][index]['liked'] = 1;
         data['recommendedJobs'][index]['likeCount']++;
      }
      this.setState({ data })
   }


   render() {


      if (this.state.redirectToSearch) {
         this.setState({ redirectToSearch: false });
         return (<Redirect to={'/search/' + Helper.buildSearchUrl(this.state.job_title, this.state.loc_name, this.state.experience)} />)
      }



      return (
         <>
            <MetaTags>
               <title>Veriipro</title>
               <meta name="title" content="Recommended Jobs |  Veriipro Jobs" />
               {/* <!-- Search Engine --> */}
               <meta name="description" content="The best online career advice and job searching website for IT professionals in the USA. You can find all kinds of job listings online with no hassle and no cost." />
               <meta name="keywords" content="best job search sites in USA, professional jobs in USA, professional jobs in USA" />
               <meta name="image" content="https://www.veriipro.com/images/123.png" />

               <link rel="canonical" href="https://www.veriipro.com/" />

               {/* <!-- Schema.org for Google --> */}
               {/* <meta itemprop="name" content="Online Job Board | Verified Professional Jobs in the USA - Veriipro Jobs" />
          <meta itemprop="description" content="The best online career advice and job searching website for IT professionals in the USA. You can find all kinds of job listings online with no hassle and no cost." />
          <meta itemprop="image" content="https://www.veriipro.com/images/123.png" /> */}

               <meta property="og:title" content="Online Job Board | Verified Professional Jobs in the USA - Veriipro Jobs" />
               <meta property="og:description" content="The best online career advice and job searching website for IT professionals in the USA. You can find all kinds of job listings online with no hassle and no cost." />
               <meta property="og:image" content="https://www.veriipro.com/assets_front/images/veriipro_app_icon.png" />


            </MetaTags>

            <div className="main-body">

               <Candidate_Header_after_login />


               <div className="container-fluid">
                  <div id="rightside" className="rside mar_tp_070">

                     {/* <div className=" breadcum">
    <ul>
        <li><Link to='/'>Home</Link> </li>
        <li>Recommended Jobs</li>
    </ul>
    </div> */}
                     <div className="job_sea___rch___lis___ting">
                        <div className="aft_lgn_pge">
                           <div className="jo__bse__arch___lis___ting"  >
                              <div className="our__re__aft_login_bth"></div>
                              <div className="se___rch____fo__rm">
                                 <form action="#" onSubmit={this.searchJob} >
                                    <div className="key___skill__serh">
                                       {/* <input type="text" className="form-control" name="job_title"  autoComplete="off" placeholder="Skills, Job Title, Company"  value={this.state.job_title} onChange={this.onChange}/> */}
                                       <Autocomplete url="v2/getskill" className="form-control" placeholder="Skills, Job Title, Company" initValue={this.state.job_title} setAutoComplete={(v) => this.setAutoComplete(v, 'job_title')} />
                                    </div>
                                    <div className="key___skill__serh">
                                       {/* <input type="text" className="form-control search-fulllocation" autoComplete="off" name="loc_name" placeholder="City, State"  value={this.state.loc_name} onChange={this.onChange}/> */}

                                       <Autocomplete url="v2/getonlylocation" className="form-control" placeholder="City, State" initValue={this.state.loc_name} setAutoComplete={(v) => this.setAutoComplete(v, 'loc_name')} />
                                       {/* <select data-placeholder="All specialisms" className="chosen-select" id="specialisms" name="specialisms">
                  <option>Location</option>
                  <option>Noida, U.p</option>
                  <option>Greater Noida, U.p</option>
                  <option>Delhi</option>
                  <option>Gurugram, Harayana</option>
               </select> */}
                                       {/* <Autocomplete
          value={this.state.loc_name2}
          items={LocationData()}
          getItemValue={item => item.label}
          shouldItemRender={renderLocationTitle}
          renderMenu={item => (
            <div className="dropdown">
              {item}
            </div>
          )}
          renderItem={(item, isHighlighted) =>
            <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
              {item.title}
            </div>
          }
          onChange={(event, loc_name2) => this.setState({ loc_name2 })}
          onSelect={loc_name2 => this.setState({ loc_name2 })}
        /> */}

                                    </div>
                                    <div className="key___skill__serh">
                                       <select className="form-control chosen-select" name='experience' value={this.state.experience} onChange={this.onChange} >
                                          <option value=''>Experience</option>
                                          <option value="0">Fresher</option>
                                          {this.buildexpOptions()}
                                       </select>
                                    </div>
                                    <div className="ser___ch_btn_btn recm_btn_clr">
                                       <button type="submit" className=""><i className="fa fa-search" aria-hidden="true"></i><span> Job Search</span></button>
                                    </div>
                                 </form>
                              </div>
                           </div>
                           <div className="jo__bs_ea__rch__fil__teng recom__mended recmd_jbs_min_hp">

                              <div className="Apl___eod__fil___rte_rec">
                                 <h1>Recommended Jobs</h1>
                              </div>
                              <div className="re__lated__jobs">
                                 {(this.state.data.recommendedJobs.length) ?
                                    <div className="sho__ww_all_jbs">
                                       {
                                          this.state.data.recommendedJobs.map((item, key) => {
                                             return <Job_list_section item={item} saveJob={this.saveJob} likeJob={this.likeJob} source="Recommended" />
                                          })
                                       }
                                    </div>
                                    :
                                    <div><p> No Recommended Jobs, Please update your profile to get recommended jobs and appear in the recruiter Search.   </p>
                                       <p><Link to={'/updateprofile'}>Click Here</Link> to complete profile</p></div>
                                 }
                              </div>
                           </div>
                           <div className="employer__blk__in__aflon">
                              <div className="Apl___eod__fil___rte_rec ex_re_nme">
                                 <h1>Top Recruiter</h1>
                              </div>
                              {
                                 this.state.data.topRecruiter.map((item, key) => {
                                    return <RecruiterCard item={item} followRec={this.followRec} />
                                 })
                              }

                           </div>
                        </div>


                        <div className="sea__rch_resu__lt_show__sec recc__mmeded__jobs">
                           <div className="jbfltwijobpost">
                              <div className="inn__left_side__sec_rec">


                                 <AdviceSection />

                                 {/*(this.state.data.topCourses.length)?
            <CourseSection item={this.state.data.topCourses} />:null*/}



                              </div>
                           </div>
                        </div>
                     </div>


                  </div>
               </div>


               <Footer />
            </div>

         </>
      );
   }
}

export default Homepage;
