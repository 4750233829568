import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags';
import Emp_header from './../common/Emp_header'
import Footer from './../common/Footer'
import { get, post, DeviceId, DeviceType } from './../../services/Apicall';
import { notify } from 'react-notify-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Constant from './../../services/Constant';
import ReactGA from 'react-ga';

const $ = window.$;

class Emp_homepage extends React.Component {
  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      'g-recaptcha-response': '',
      deviceid: DeviceId(),
      devicetype: DeviceType(),
      redirectToReferrer: false,
      otp: '',
      userid: '',
      eyeicon: false,
    };
  }

  componentDidMount() {

    //console.log('Trigger .... Page view GA-- ' + window.location.pathname + window.location.search);
    // This line will trigger on a route change
    ReactGA.initialize('UA-151243375-1');
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);


    // get('v2/homepage', {}).then((result) => {
    //   let responseJson = result;
    //   if (responseJson.data && responseJson.error === false) {
    //     this.setState({ data: responseJson.data });
    //   } else if (responseJson.error === true) {
    //     notify.show('Something went wrong while loading data.', 'error');
    //   }
    // });
  }

  login = (e) => {
    e.preventDefault();
    if (this.state.email && this.state.password) {
      post('v2/emp/login', this.state).then((result) => {
        let responseJson = result;

        if (responseJson.data && responseJson.error === false) {
          localStorage.setItem('emp_name', responseJson.data.contact_person);
          localStorage.setItem('emp_company', responseJson.data.company_name);

          localStorage.setItem('emp_mobile', responseJson.data.mobile);
          localStorage.setItem('emp_email', responseJson.data.email);
          localStorage.setItem('emp_id', responseJson.data.id);
          localStorage.setItem('emp_pid', responseJson.data.parent_id);
          if (responseJson.data.image == null)
            localStorage.setItem('emp_image', '');
          else localStorage.setItem('emp_image', responseJson.data.image);

          if (responseJson.data.logo == null)
            localStorage.setItem('emp_logo', '');
          else localStorage.setItem('emp_logo', responseJson.data.logo);
          localStorage.setItem('emp_imageurl', responseJson.imageurl);
          localStorage.setItem('emp_token', responseJson.access_token);


          localStorage.setItem('emp_total_download', responseJson.data.total_profileview);
          localStorage.setItem('emp_total_jobpost', responseJson.data.total_jobpost);
          localStorage.setItem('emp_used_download', responseJson.data.used_download);
          localStorage.setItem('emp_used_jobpost', responseJson.data.used_jobpost);
          localStorage.setItem('emp_plan_start', responseJson.data.plan_start);
          localStorage.setItem('emp_plan_end', responseJson.data.plan_end);
          localStorage.setItem('emp_last_login', responseJson.data.last_login);
          localStorage.setItem('emp_a_candidate_search', responseJson.data.candidate_search);
          localStorage.setItem('emp_a_candidate_view', responseJson.data.candidate_view);
          localStorage.setItem('emp_a_candidate_interview', responseJson.data.candidate_interview);
          localStorage.setItem('emp_a_job_view', responseJson.data.job_view);
          localStorage.setItem('emp_a_job_posting', responseJson.data.job_posting);
          localStorage.setItem('emp_a_update_job_status', responseJson.data.update_job_status);
          localStorage.setItem('emp_a_share_job', responseJson.data.share_job);
          localStorage.setItem('emp_a_preminum_search', responseJson.data.preminum_search);

          //window.location.reload(false);
          //this.props.history.push('/')
          if (responseJson.data.isProfileComplete === false)
            this.setState({ redirectToProfile: true });
          else this.setState({ redirectToReferrer: true });
          this.setState({ redirectToReferrer: true });
        } else if (responseJson.error === true && responseJson.uid) {
          notify.show('Please Verify Your Account', 'warning');
          this.setState({ userid: responseJson.uid });
        } else if (responseJson.error === true) {
          notify.show(responseJson.msg, 'error');
        }
      });
      window.grecaptcha.reset();
    } else {
      notify.show('Please Enter a valid Email and Password', 'error');
    }
    // window.location.href='/employer/Dashboard';
  };

  handleTogle = () => {
    this.setState({ eyeicon: !this.state.eyeicon });
  };

  verifyaccount = (e) => {
    e.preventDefault();
    if (this.state.otp && this.state.userid) {
      post('v2/emp/signupotpverify', this.state).then((result) => {
        let responseJson = result;
        if (responseJson.data && responseJson.error === false) {
          localStorage.setItem('emp_name', responseJson.data.contact_person);
          localStorage.setItem('emp_company', responseJson.data.company_name);

          localStorage.setItem('emp_mobile', responseJson.data.mobile);
          localStorage.setItem('emp_email', responseJson.data.email);
          localStorage.setItem('emp_id', responseJson.data.id);
          localStorage.setItem('emp_pid', responseJson.data.parent_id);
          if (responseJson.data.image == null)
            localStorage.setItem('emp_image', '');
          else localStorage.setItem('emp_image', responseJson.data.image);

          if (responseJson.data.logo == null)
            localStorage.setItem('emp_logo', '');
          else localStorage.setItem('emp_logo', responseJson.data.logo);
          localStorage.setItem('emp_imageurl', responseJson.imageurl);
          localStorage.setItem('emp_token', responseJson.access_token);
          if (responseJson.data.isProfileComplete === false)
            this.setState({ redirectToProfile: true });
          else this.setState({ redirectToReferrer: true });
          notify.show('Welcome to the Network of Professionals', 'success');
        } else if (responseJson.error === true) {
          notify.show(responseJson.msg, 'error');
        }
      });
    }
  };

  resendOtp = (e) => {
    e.preventDefault();
    if (this.state.userid) {
      post('v2/emp/resendsignupotp', { user_id: this.state.userid }).then(
        (result) => {
          let responseJson = result;
          if (responseJson.msg && responseJson.error === false) {
            notify.show(
              'OTP sent to your Registered Email ID ' + this.state.email,
              'success'
            );
          } else if (responseJson.error === true) {
            notify.show(responseJson.msg, 'error');
          }
        }
      );
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    if (this.state.redirectToReferrer || localStorage.getItem('emp_token')) {
      /* if(this.props.location.search){
          var qs =this.props.location.search.split('?ref=');
          var ref= qs[1];
          if(ref!=undefined)
              window.location.href=ref;
          else
              window.location.href='/';
      }else*/
      window.location.href = '/employer/';
    }

    return (
      <>
        <MetaTags>
          <title>Online Tech Job Posting Portal for Employer in USA - Veriipro Jobs </title>
          <meta name="title" content="Online Tech Job Posting Portal for Employer in USA - Veriipro Jobs" />
          {/* <!-- Search Engine --> */}
          <meta name="description" content="Now Post a Job Online in Minutes! Reach out to multiple Job Seekers. Hire Local Talent in the USA. Number 1 Job Site. Post a Job in Minutes. " />
          <meta name="keywords" content="best job search sites in USA, professional jobs in USA, professional jobs in USA" />
          <meta name="image" content="https://www.veriipro.com/images/123.png" />

          <link rel="canonical" href={"https://www.veriipro.com" + this.props.match.url} />

          {/* <!-- Schema.org for Google --> */}
          {/* <meta itemprop="name" content="Online Job Portal for Employer in the USA - Veriipro Jobs" />
          <meta itemprop="description" content="Now Post a Job Online in Minutes! Reach out to multiple Job Seekers. Hire Local Talent in the USA. Number 1 Job Site. Post a Job in Minutes. " />
          <meta itemprop="image" content="https://www.veriipro.com/images/123.png" /> */}

          <meta property="og:title" content="Online Job Portal for Employer in USA - Veriipro Jobs" />
          <meta property="og:description" content="Now Post a Job Online in Minutes! Reach out to multiple Job Seekers. Hire Local Talent in the USA. Number 1 Job Site. Post a Job in Minutes. " />
          <meta property="og:image" content="https://www.veriipro.com/assets_front/images/veriipro_app_icon.png" />


        </MetaTags>

        <div className="main-body emp_home_pge_wol">
          <Emp_header transparent={true} />

          {/* banner */}
          <div className="main_banner_top_header">
            <section className="banner-area" id="home">
              <div className="overlay overlay-bg"></div>
              <div className="container">
                <div className="row fullscreen d-flex align-items-center justify-content-center">
                  <div className="banner-content col-lg-12">
                    <div className="container" id="login-container_left">
                      <h1>
                        <span className="pos_fre">Post free Jobs</span> <br />
                        <span className="search_can">
                          {' '}
                          Attract the Right Talent
                        </span>
                      </h1>
                    </div>
                    <div className="container" id="login-container">
                      <div className="main-container-inner">
                        <div className="container-form">
                          <form
                            onSubmit={this.login}
                            autoComplete="new-password"
                            className="ban_login_form"
                            style={
                              this.state.userid === ''
                                ? { display: 'block' }
                                : { display: 'none' }
                            }
                          >
                            <h3 className="login_form_h3">Employer login </h3>
                            <input
                              type="email"
                              name="email"
                              onChange={this.onChange}
                              required
                              className="input-email input"
                              id="email"
                              placeholder="Email"
                              maxLength="80"
                              autoComplete="new-password"
                            />
                            <br />
                            <p className="errors" id="emailError"></p>
                            <div className="password_eye emp">
                              <input
                                type={this.state.eyeicon ? 'text' : 'password'}
                                name="password"
                                onChange={this.onChange}
                                required
                                className="input-password input"
                                id="large-password"
                                placeholder="Password"
                                maxLength="20"
                                autoComplete="new-password"
                              />
                              {this.state.eyeicon ? (
                                <span class="fa fa-eye-slash hideshow_pass" aria-hidden="true" onClick={this.handleTogle}></span>
                              ) : (
                                  <span
                                    class="fa fa-eye hideshow_pass"
                                    aria-hidden="true"
                                    onClick={this.handleTogle}
                                  ></span>
                                )}
                            </div>
                            <div className="col-lg-12 form-group recaptcha_style">
                              <ReCAPTCHA
                                ref={(e) => (this.captcha = e)}
                                sitekey={Constant._SITE_RECAPTCHA_SITE_KEY}
                                onChange={(value) => {
                                  this.setState({
                                    'g-recaptcha-response': value,
                                  });
                                }}
                              />
                            </div>
                            <div className="message-display">
                              <p id="submit-message"></p>
                            </div>
                            <button type="submit" className="button-submit">
                              Login
                            </button>
                          </form>
                          <form
                            onSubmit={this.verifyaccount}
                            autoComplete="off"
                            className="ban_login_form"
                            style={
                              this.state.userid != ''
                                ? { display: 'block' }
                                : { display: 'none' }
                            }
                          >
                            <h3 className="login_form_h3">Recruiter Login</h3>
                            <input
                              type="text"
                              maxLength={6}
                              name="otp"
                              onChange={this.onChange}
                              required
                              className="input-email input"
                              id="otp"
                              placeholder="Enter OTP sent on registered email"
                              maxLength="6"
                              autoComplete="new-password"
                            />
                            <br />
                            <div className="message-display">
                              <p id="submit-message"></p>
                            </div>
                            <button type="submit" className="button-submit">
                              Verify
                            </button>
                            <div className="col-lg-12 login-btm forgot_pass_word">
                              <a href="#" onClick={this.resendOtp} className="">
                                Resesnd OTP
                              </a>
                            </div>{' '}
                          </form>
                        </div>
                        <div
                          className="forgot-password main-container-footer"
                          id="forgotPasswordLarge"
                        >
                          <p>
                            <Link
                              to="/employer/forget_password"
                              className="forgot-password_link"
                              id="forgot-password_sec"
                            >
                              Forgot Password?
                            </Link>
                          </p>
                        </div>
                        <div className="main-container-footer">
                          <p>
                            New to VeriiPro?
                            <Link
                              to="/employer/registration"
                              className="sign-up"
                              id="sign-up"
                            >
                              {' '}
                              Sign up now!
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* end of banner */}

          <div className="emp_home_page_section_box">
            <section className="emp_home_down_secton">
              <div className="emp_home_page_before">
                <div className="emp_home_two_side">
                  <div className="emp_home_right_side even"></div>

                  <div className="emp_home_left_side_content odd">
                    <h2>Post free Jobs with VeriiPro</h2>
                    <ul>
                      <li>Post a job to attract right candidates to apply</li>

                      <li>
                        Encourage pre-screening process to target best-fit
                        candidates
                      </li>

                      <li>Maintain company profile for better insight</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section className="emp_home_down_secton">
              <div className="emp_home_page_before">
                <div className="emp_home_two_side">
                  <div className="emp_home_right_side odd"></div>

                  <div className="emp_home_left_side_content even">
                    <h2>Search and Review Candidates</h2>
                    <ul>
                      <li>Get recommended candidates against a Job</li>

                      <li>Refined search mechanism</li>

                      <li>Better team collaboration</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section className="emp_home_down_secton">
              <div className="emp_home_page_before">
                <div className="emp_home_two_side">
                  <div className="emp_home_right_side even"></div>

                  <div className="emp_home_left_side_content odd">
                    <h2>Get best-fit talent on-board</h2>
                    <ul>
                      <li>Chat with candidate for effective communication</li>

                      <li>Schedule interview to hire</li>

                      <li>Reduce your cost of hiring</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <section className="emp_home_down_secton">
              <div className="emp_home_page_before">
                <div className="emp_home_two_side">
                  <div className="emp_home_right_side odd"></div>

                  <div className="emp_home_left_side_content even">
                    <h2>Get Premium Services</h2>
                    <ul>
                      <li>Focus on Faster Sourcing</li>

                      <li>We source and screen candidates</li>

                      <li>Save your time-to-hire with our expert recruiters</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}
export default Emp_homepage;
