import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import Popup from "reactjs-popup";
import { put } from './../../services/Apicall';
import { notify } from 'react-notify-toast';
import * as Config from './../../services/Configuration'
import * as Helper from './../../services/Helper'
import {CopyToClipboard} from 'react-copy-to-clipboard';
//import Header from './../../common/Header';;

const $ = window.$;

class Job_list_section extends React.Component {

  constructor() {
    super();
    this.state = {
      comments: [],
      msg: '',
      imageurl: ''
    }
  }

  componentDidMount() {

    put('v2/fetchjobcomment', { job_id: (this.props.item.job_id) ? this.props.item.job_id : this.props.item.id }).then((result) => {
      let responseJson = result;
      if (responseJson.error === false) {
        this.setState({ comments: responseJson.data, imageurl: responseJson.imageurl })
      } else {
        //notify.show('Something Went Wrong;!', 'error');
      }
    });
  }

  submitComment = (e) => {
    e.preventDefault();
    if (!localStorage.ud_token) {
      notify.show('Please Login to Add Comment', 'error');
    } else if (this.state.msg.length < 4) {
      notify.show('Enter at least few word', 'error');
    } else if (this.state.msg.length > 500) {
      notify.show('Message cannnot be of more than 500 characters.', 'error');
    } else if (!this.props.item.job_id && !this.props.item.id) {
      notify.show('Something went wrong', 'error');
    } else {

      put('v2/jobcomment', { job_id: (this.props.item.job_id) ? this.props.item.job_id : this.props.item.id, msg: this.state.msg }).then((result) => {
        let responseJson = result;
        if (responseJson.error === false) {
          this.componentDidMount();
          this.setState({ msg: '' });
          notify.show('Comment is Added', 'success');
        } else if (responseJson.error === true) {
          //notify.show('Something went wrong while loading data.', 'error');
        }
      });


    }

  }

  saveJob = (job_id, action) => {
    if (action == 'login') {
      var org = this.props.location.pathname;
      this.props.history.push('/jobseeker/login' + '?ref=' + org)
    }
    else
      put('v2/savejob', { action: action, job_id: job_id }).then((result) => {
        let responseJson = result;
        if (responseJson.error === false) {
          //  notify.show('Job saved successfully!', 'success');
          this.props.saveJob(job_id, action)
        } else {
          notify.show('Something Went Wrong!', 'error');
        }
      });
  }


  likeJob = (job_id, action) => {
    if (action == 'login') {
      notify.show('Please Login to Like the Job!', 'warning');
      var org = this.props.location.pathname;
      this.props.history.push('/jobseeker/login' + '?ref=' + org)
    }
    else
      put('v2/likejob', { action: action, job_id: job_id }).then((result) => {
        let responseJson = result;
        if (responseJson.error === false) {
          this.props.likeJob(job_id, action)
        } else {
          notify.show('Something Went Wrong!', 'error');
        }
      });
  }
  shareSocial = (type) => {
    let shareurl = (this.props.item.slug) ? "/job/" + this.props.item.slug : (this.props.item.job_id) ? "/job/" + this.props.item.job_id : "/job/" + this.props.item.id;
    let url = '';

    if (type == 'facebook')
      url = 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.hostname + shareurl;
    else if (type == 'linkedin')
      url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + window.location.hostname + shareurl;
    else if (type == 'twitter')
      url = 'http://twitter.com/share?text=' + window.location.hostname + shareurl + '&url=' + window.location.hostname + shareurl + '&hashtags=Veriipro,JobsInUS,VerifiedJobs';
    else if (type == 'email') {
      let ccemail = localStorage.emp_email ? localStorage.emp_email : '';
      url = "mailto:" + ccemail +
       //"?cc=" + ccemail + 
       "&subject=" + escape('Hey sharing a job with you from veriipro.com ')  + "&body=" + escape('Hello, \n I found this great opportunity on veriipro.com, and thought it might be helpful in your job search. Please Check the link \n\n ' + window.location.hostname + shareurl);

    }
    //console.log(url)
    if (type == 'email')
      window.location.href = url;
    else
      window.open(url, 'targetWindow', 'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250');
  }

  render() {
    let searchRef;
    if (this.props.source !== undefined)
      searchRef = '?search=' + this.props.match.url + '&source=' + this.props.source;
    else
      searchRef = this.props.location.search;
    return (
      <>
        {/* job end */}

        <div className="re__lated__jobs">

          <div className="sho__ww_all_jbs">
            <div className="rel__late_job_block">

              <div className="jb__full_det_aail">

                <div className="to__pme">
                  <div className="single__row_job_list">
                    <Link to={(this.props.item.slug) ? "/job/" + this.props.item.slug + searchRef : (this.props.item.job_id) ? "/job/" + this.props.item.job_id + searchRef : "/job/" + this.props.item.id + searchRef}>
                      <span className="com___pny__nme" style={{ textTransform: 'capitalize' }}>{this.props.item.job_title}</span>
                    </Link>
                  </div>
                </div>
                <div className="aa___jb__ddee">

                  <div className="single__row_job_list" title='Company'>
                    <div className="sig__le_ont">
                      <span className="pre__imum_job"><i className="fa fa-briefcase"></i></span>
                      <span className="com___pny__nme real_company" style={{ textTransform: 'capitalize' }}>{this.props.item.company_name}</span>
                    </div>
                    <div className="sig__le_ont" title='Job Locations'>
                      <span className="pre__imum_job"><i className="fa fa-map-marker-alt"></i></span>
                      <span className="com___pny__nme" style={{ textTransform: 'capitalize' }} >
                        {(this.props.item.city_name) ?
                          this.props.item.city_name.join()
                          :
                          this.props.item.work_from_home ?
                            'Work from home ' : this.props.item.remote_location ?
                              'Remote Job ' : ' - '
                        }</span>
                    </div>
                    <div className="sig__le_ont" title='Compensation Detail'>
                      <span className="pre__imum_job"><i className="fas fa-dollar-sign"></i></span>
                      <span className="com___pny__nme">
                        {
                          (this.props.item.salary_min != '' && this.props.item.salary_max != '') ?
                            Config.defaultCurrencySymbol + Helper.numberWithCommas(this.props.item.salary_min) + '-' + Helper.numberWithCommas(this.props.item.salary_max) + ' ' + this.props.item.salary_type
                            :
                            (this.props.item.salary_min != '' && this.props.item.salary_max == '') ?
                              Config.defaultCurrencySymbol + Helper.numberWithCommas(this.props.item.salary_min) + ' ' + this.props.item.salary_type
                              :
                              (this.props.item.salary_min == '' && this.props.item.salary_max != '') ?
                                'Up to ' + Config.defaultCurrencySymbol + Helper.numberWithCommas(this.props.item.salary_max) + ' ' + this.props.item.salary_type
                                :
                                this.props.item.salary_extra ?
                                  ' Depend on Experience '
                                  :
                                  'Not Disclosed'
                        }
                      </span>
                    </div>
                  </div>
                  <div className="single__row_job_list" title='Employment Type'>

                    {(this.props.item.experience_min || this.props.item.experience_max || this.props.item.experience_extra) ?
                      <div className="sig__le_ont" title='Required Experience'>
                        <span className="pre__imum_job"><i className="fa fa-history"></i></span>
                        <span className="com___pny__nme">
                          {this.props.item.experience_min}
                          {
                            (this.props.item.experience_min != '' && this.props.item.experience_max != '') ?
                              ' to '
                              :
                              (this.props.item.experience_min != '' && this.props.item.experience_max == '') ?
                                ' + '
                                :
                                (this.props.item.experience_min == '' && this.props.item.experience_max != '') ?
                                  ' up to '
                                  :
                                  '  '
                          }
                          {(this.props.item.experience_min != '' || this.props.item.experience_max != '') ?
                            this.props.item.experience_max + ' ' + this.props.item.experience_type + ' Exp.'
                            :
                            this.props.item.experience_extra ? ' ' + this.props.item.experience_extra + ' ' : ''
                          }
                        </span>
                      </div> : null}

                    {(this.props.item.jobtype_name) ?
                      <div className="sig__le_ont" title='Employment Type'>
                        <span className="pre__imum_job"><i className="fa fa-hourglass-half"></i></span>
                        <span className="com___pny__nme">{(this.props.item.jobtype_name) ? this.props.item.jobtype_name.join(', ') : '-'}</span>
                      </div> : null}

                    {/*(this.props.item.workauth_name && this.props.item.workauth_name[0]) ?
                      <div className="single__row_job_list" >
                        <div className="sig__le_ont" title='Acceptable Work Authorizations'>

                          <span className="com___pny__nme limit__ed__word">
                            <span className="pre__imum_job"><i className="fa fa-globe"></i></span>{(this.props.item.workauth_name) ? this.props.item.workauth_name.join() : '- '}</span>
                        </div>
                      </div> : null*/}

                  </div>

                  <div className="sig__le_ont fu___lldet__ail" title='Skills Reuired'>
                    {/* <span className="pre__imum_job"><i className="fa fa-clipboard"></i> Skills</span>
<span className="com___pny__nme"> */}

                    <span className="jd_skill_detail">
                      <span className="jd_skill_title"><i className="fa fa-clipboard"></i>Skills</span>
                      {this.props.item.key_skill}</span>
                  </div>

                </div>
              </div>
              <div className="jb__full_det_aail_logo">
                <Link to={(this.props.item.slug) ? "/job/" + this.props.item.slug : (this.props.item.job_id) ? "/job/" + this.props.item.job_id : "/job/" + this.props.item.id}>
                  {/* <img src={(this.props.item.comp_logo) ? this.props.item.imageurl + this.props.item.comp_logo : "/../images/defult_logo.png"} alt="veriipro"  /> */}
                  <picture>
                    <source width="640" height="360" alt="veriipro" srcset={(this.props.item.comp_logo) ? this.props.item.imageurl + this.props.item.comp_logo : "/../images/defult_logo.webp"} />
                    <source width="640" height="360" alt="veriipro" srcset={(this.props.item.comp_logo) ? this.props.item.imageurl + this.props.item.comp_logo : "/../images/defult_logo.png"} />
                    <img src={(this.props.item.comp_logo) ? this.props.item.imageurl + this.props.item.comp_logo : "/../images/defult_logo.png"} alt="logout" width="640" height="360" />
                  </picture>
                </Link>
              </div>
              <div class="li_sh_com">
                {(this.props.item.applied) ?
                  <span className="com___pny__nme flrihtsd">Applied on :  {this.props.item.appliedOn ? Helper.formatDate(this.props.item.appliedOn) : Helper.formatDate(this.props.item.appliedDate)}</span>
                  :
                  (this.props.item.saved) ?
                    <span className="com___pny__nme flrihtsd">Saved on :  {this.props.item.saveDate ? Helper.formatDate(this.props.item.saveDate) : this.props.item.savedDate ? Helper.formatDate(this.props.item.savedDate) : Helper.formatDate(new Date())}</span>
                    :
                    <span className="com___pny__nme flrihtsd">Posted on :  {Helper.formatDate(this.props.item.created_at)}</span>
                }



                {
                  (localStorage.ud_token) ?
                    (this.props.item.liked) ?
                      <i class={"fas fa-thumbs-up"} aria-hidden="true" onClick={() => this.likeJob(this.props.item.job_id, 'unlike')}> <span> like {this.props.item.likeCount ? this.props.item.likeCount : ''} </span> </i>
                      :
                      <i class={"far fa-thumbs-up"} aria-hidden="true" onClick={() => this.likeJob(this.props.item.job_id, 'like')}> <span> like {this.props.item.likeCount ? this.props.item.likeCount : ''} </span> </i>
                    :
                    <i class={"far fa-thumbs-up"} title='Login to Save this Job' aria-hidden="true" onClick={() => this.likeJob(this.props.item.job_id, 'login')}> <span> like {this.props.item.likeCount ? this.props.item.likeCount : ''} </span> </i>

                }



                <Popup trigger={<i class="far fa-comment-alt pop_clic_for_commet"> <span> Comment {this.props.item.commentCount ? this.props.item.commentCount : ''} </span> </i>} modal>
                  {close => (
                    <div className="modal">
                      <a className="close" onClick={close}>
                        &times;
        </a>
                      <div className="header"> Add your Comments </div>
                      <div className="content">
                        {" "}

                        <div className="main_comen__sect_box">
                          <div className="comme_box_pop_show">

                            {/* comment section */}

                            <div class="detailBox">
                              <div class="commentBox">

                                <p class="taskDescription">

                                  <form class="form-inline" role="form" onSubmit={(e) => this.submitComment(e)}>
                                    <div class="form-group">
                                      <textarea className="form-control" maxLength={500} name="msg" value={this.state.msg} onChange={(e) => { this.setState({ msg: e.target.value }) }} ></textarea>

                                    </div>
                                    <div class="form-group">
                                      <button type='submit' class="btn btn-default">Add Comment</button>
                                    </div>
                                    <span className="pull-right">{this.state.msg.length}/500</span>
                                  </form>

                                </p>
                              </div>
                              <div class="actionBox">
                                <ul class="commentList">

                                  {this.state.comments.map((i, k) => {
                                    return <li key={k}>
                                      <div class="commenterImage">
                                        {/* <img src={(i.image) ? this.state.imageurl + i.image : '/../images/profile_.png'} /> */}
                                        <picture>
                                          <source width="640" height="360" alt="profile" title="profile" src={(i.image) ? this.state.imageurl + i.image : '/../images/profile_.webp'} />
                                          <source width="640" height="360" alt="profile" title="profile" src={(i.image) ? this.state.imageurl + i.image : '/../images/profile_.png'} />
                                          <img src={(i.image) ? this.state.imageurl + i.image : '/../images/profile_.png'} alt="profile" title="profile" width="640" height="360" />
                                        </picture>
                                      </div>
                                      <div class="commentText">
                                        <p class=""><strong>{i.first_name + ' ' + i.last_name}</strong></p>
                                        <p class="">{i.comment_text}</p>
                                        <span class="date sub-text">{Helper.formatDateTime(i.created_at)}</span>

                                      </div>
                                    </li>
                                  })
                                  }
                                  {this.state.comments.length == 0 ? <li style={{ textAlign: 'center' }}>No Comments to display</li> : ''}



                                </ul>
                              </div>
                            </div>

                            {/* end of comment section */}

                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>


                <i class="fa fa-share-alt popup_win" aria-hidden="true">
                  <div className="socail_icon_on_hover">
                    <div className="litwfbpiinmlwp"  title="Share on linkedin"
                     onClick={() => this.shareSocial('linkedin')}> <i className="fab fa-linkedin"></i> </div>
                    <div className="litwfbpiinmlwp"  title="Share on twitter"
                    onClick={() => this.shareSocial('twitter')}> <i className="fab fa-twitter"></i> </div>

                    <div className="litwfbpiinmlwp"  title="Share on facebook" 
                    onClick={() => this.shareSocial('facebook')}> <i className="fab fa-facebook"></i> </div>

                    <div className="litwfbpiinmlwp"  title="Share via email"
                    onClick={() => this.shareSocial('email')}> <i className="fa fa-envelope"></i> </div>
                    
                    <CopyToClipboard text={ window.location.hostname +'/job/'+(this.props.item.slug?this.props.item.slug:(this.props.item.job_id?this.props.item.job_id:this.props.item.id))}
                      onCopy={() => notify.show('Copied', 'success')
                      }
                      >
                    <div className="litwfbpiinmlwp" title="Copy Link"> <i className="fa fa-copy"></i> </div>
                    </CopyToClipboard>

                    {/* <div className="litwfbpiinmlwp"> <i className="fab fa-instagram"></i> </div>
  <div className="litwfbpiinmlwp"> <i className="fab fa-whatsapp"></i> </div> */}
                  </div>
                  <span> Share </span> </i>


                {
                  (localStorage.ud_token) ?
                    (this.props.item.saved) ?
                      <span className="svae__job_btn_me" title='Remove this Job from Saved' onClick={() => this.saveJob(this.props.item.job_id, 'remove')}><i className="fa fa-bookmark"></i>Saved</span>
                      :
                      <span className="svae__job_btn_me" title='Save this Job' onClick={() => this.saveJob(this.props.item.job_id, 'add')}><i className="far fa-bookmark"></i>Save</span>
                    :
                    <span className="svae__job_btn_me" title='Login to Save this Job' onClick={() => this.saveJob(this.props.item.job_id, 'login')}><i className="far fa-bookmark"></i>Save</span>
                }
              </div>

              {(this.props.premium == true) ?
                <div class="ribbon ribbon-top-right"><span>Premium</span></div> : null
              }
            </div>

          </div>
        </div>


      </>
    );
  }

}
export default withRouter(Job_list_section); {/* job end */ }
