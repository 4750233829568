import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { notify } from 'react-notify-toast';

import { get } from './../../services/Apicall';
//import Header from './../../common/Header';;

const $ = window.$;

class Header_after_login extends React.Component {

  constructor() {
    super();
    this.state = {
      appliedCount: 0,
      savedCount: 0,
      viewCount: 0,
      profile_status: 1
    }
  }

  componentDidMount() {
    get('v2/headercount', {}).then((result) => {
      let responseJson = result;
      if (responseJson.error == false) {
        this.setState(responseJson);
      }
    });

  }


  logout = () => {
    localStorage.removeItem('ud_fname');
    localStorage.removeItem('ud_lname');
    localStorage.removeItem('ud_mobile');
    localStorage.removeItem('ud_email');
    localStorage.removeItem('ud_tid');
    localStorage.removeItem('ud_id');
    localStorage.removeItem('ud_token');

    notify.show('You have been Signed off...', 'success');
    this.props.redirect(true);

    // this.setState({redirectToReferrer: true});


  }

  render() {
    return (
      <>
        <header id="header" className={(this.props.transparent) ? "main_header" : "main_header withtranclr"}>
          <nav className="navbar navbar-inverse navbar-fixed-top" id="candidate" role="navigation">
            <div className="container">
              <div className="navbar-header">
                <div className="dropdown mobile_only">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                    <span class="profile_picture">
                      {(localStorage.ud_image) ?
                        <img src={localStorage.ud_imageurl + localStorage.ud_image} alt="profile" title="profile" title="" /> :
                        // <img src="/../images/profile.png" alt="veriipro"  title=""/>
                        <picture>
                          <source width="640" height="360" alt="profile" title="profile" srcset="/images/profile.webp" />
                          <source width="640" height="360" alt="profile" title="profile" srcset="/images/profile.png" />
                          <img src="/images/profile.png" alt="profile" title="profile" width="640" height="360" />
                        </picture>
                      }
                    </span>
                    {/* <span className="drpdm_jbme">Me <i className="caret"></i></span> */}
                  </a>
                  <ul className="dropdown-menu dropdown-lr animated slideInRight" role="menu">
                    <div className="col-lg-12 drop_down_menu_bar">
                      <div className="mprsecnmempr">
                        <div className="proimgsec" style={{ display: 'none' }} >
                          <Link to="/profileview">
                            {(localStorage.ud_image) ?
                              <img src={localStorage.ud_imageurl + localStorage.ud_image} style={{ width: 50 }} alt="profile" title="profile" title="" /> :
                              // <img src="/../images/profile.png" style={{width:50}} alt="veriipro"  title="" />
                              <picture>
                                <source width="640" height="360" style={{ width: 50 }} alt="profile" title="profile" srcset="/images/profile.webp" />
                                <source width="640" height="360" style={{ width: 50 }} alt="profile" title="profile" srcset="/images/profile.png" />
                                <img src="/images/profile.png" style={{ width: 50 }} alt="profile" title="profile" width="640" height="360" />
                              </picture>
                            }
                          </Link>
                        </div>
                        <div className="prodetsecne">
                          <Link to="/profileview">
                            <p className="prnameprt">{localStorage.getItem('ud_fname') + ' ' + localStorage.getItem('ud_lname')}</p>
                            <p className="premailprt">{localStorage.getItem('ud_email')}</p>
                          </Link>
                        </div>
                      </div>
                      {/* <div className="text-center lstlogidetail"><h3>Last Login: 2 days ago</h3></div> */}
                      <div className="text-center"><h3><Link to="/updateprofile">My Profile</Link></h3></div>
                      <div className="text-center"><h3><Link to="/settings">Setting</Link> </h3></div>
                      <div className="text-center"><h3><Link to="/messages/13">Messages</Link> </h3></div>
                      <div className="text-center"><h3><Link to="/contact-us">Contact us</Link> </h3></div>
                      <div className="text-center"><h3><a href='#' onClick={this.logout}>Logout</a></h3></div>
                    </div>
                  </ul>
                </div>
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <Link className="navbar-brand" to="/">
                  <img className="main_logo_header" src="https://www.veriipro.com/assets_front/images/Veriipro%20Logo-03.png" alt="veriipro" title="" />

                </Link>
              </div>
              <div id="navbar" className="collapse navbar-collapse">
                <ul className="nav navbar-nav navbar-right">
                  <li className={(window.location.pathname == '/') ? 'activeMenu' : ''}>
                    <Link to="/">
                      <span>
                        <picture>
                          <source srcset="/../images/deshboard.webp" alt="jobsearch" title="Job Search" importance="high" type="image/webp" />
                          <source srcset="/../images/deshboard.png" alt="jobsearch" title="Job Search" type="image/png" />
                          <img src="/../images/deshboard.png" alt="jobsearch" title="Job Search" />
                        </picture>
                      </span><span>Job Search</span></Link>
                  </li>
                  {/* <li className="dropdown">
          <a href="http://phpoll.com/register" className="dropdown-toggle" data-toggle="dropdown"><span><img src="/../images/job.png" alt="veriipro"  title=""/></span><span className="drpdm_jbme">Jobs</span> <span className="caret"></span></a>
          <ul className="dropdown-menu dropdown-lr animated flipInX" role="menu">
            <div className="col-lg-12">
              <div className="text-center">
                <h3>My Jobs</h3>
              </div>
              <div className="text-center">
                <h3>Post Jobs</h3>
              </div>
              <div className="text-center">
                <h3>Tagged candidates</h3>
              </div>
              <div className="text-center">
                <h3>shortlisted candidates</h3>
              </div>
            </div>
          </ul>
        </li> */}
                  <li className={(window.location.pathname == '/applied-jobs') ? 'activeMenu' : ''}>
                    <Link to="/applied-jobs">

                      <span className="counter-menu_">
                        {(this.state.appliedCount) ? <i className="counter__clss">{this.state.appliedCount}</i> : null}
                        <picture>
                          <source srcset="/../images/applied.webp" alt="applied jobs" title="Applied Jobs" importance="high" type="image/webp" />
                          <source srcset="/../images/applied.png" alt="applied jobs" title="Applied Jobs" type="image/png" />
                          <img src="/../images/applied.png" alt="applied jobs" title="Applied Jobs" />
                        </picture>
                      </span>
                      <span>Applied Jobs</span></Link>
                  </li>
                  <li className={(window.location.pathname == '/employer-viewed') ? 'activeMenu' : ''}>
                    <Link to="/employer-viewed">
                      <span>
                        {(this.state.viewCount) ? <i className="counter__clss view_counter">{this.state.viewCount}</i> : null}
                        <picture>
                          <source srcset="/../images/views.webp" alt="profile views" title="Profile Views" importance="high" type="image/webp" />
                          <source srcset="/../images/views.png" alt="profile views" title="Profile Views" type="image/png" />
                          <img src="/../images/views.png" alt="profile views" title="Profile Views" />
                        </picture>
                      </span>
                      <span>Views  </span></Link>
                  </li>
                  <li className={(window.location.pathname == '/saved-jobs') ? 'activeMenu' : ''}>
                    <Link to="/saved-jobs">

                      <span>
                        {(this.state.savedCount) ? <i className="counter__clss">{this.state.savedCount}</i> : null}
                        <picture>
                          <source srcset="/../images/saved.webp" alt="saved jobs" title="Saved Jobs" importance="high" type="image/webp" />
                          <source srcset="/../images/saved.png" alt="saved jobs" title="Saved Jobs" type="image/png" />
                          <img src="/../images/saved.png" alt="saved jobs" title="Saved Jobs" />
                        </picture>
                      </span><span>Saved Jobs</span></Link>
                  </li>
                  {/* <li>
          <Link to="/candidate/Candidate_recomm_job">
          <span className="counter__clss">10</span>
          <span><img src="/../images/recom.png" alt="veriipro"  title=""/></span><span>Recomm.</span></Link>
        </li>                         */}

                  {/* <li className="dropdown">
          <a href="https://learning.veriipro.com" target="_blank"><span><img src="/../images/elearn.jpg" alt="eLearning" title=""/></span><span>E-learning</span> </a>
        </li> */}
                  <li className="dropdown desktop_only">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                      <span className="profile_picture">
                        {(localStorage.ud_image) ?
                          <img src={localStorage.ud_imageurl + localStorage.ud_image} alt="Profile" title="Profile" title="" /> :
                          // <img src="/../images/profile.png"/>
                          <picture>
                            <source width="640" height="360" alt="Profile" title="Profile" srcset="/images/profile.webp" />
                            <source width="640" height="360" alt="Profile" title="Profile" srcset="/images/profile.png" />
                            <img src="/images/profile.png" alt="Profile" title="Profile" width="640" height="360" />
                          </picture>
                        }
                      </span>
                      {/* <span className="drpdm_jbme">Me <i className="caret"></i></span> */}
                    </a>
                    <ul className="dropdown-menu dropdown-lr animated slideInRight" role="menu">
                      <div className="col-lg-12 drop_down_menu_bar">
                        <div className="mprsecnmempr">
                          <div className="proimgsec"   >
                            <Link to="/profileview">
                              {(localStorage.ud_image) ?
                                <img src={localStorage.ud_imageurl + localStorage.ud_image} alt="Profile" title="Profile" title="" /> :
                                // <img src="/../images/profile.png"/>
                                <picture>
                                  <source width="640" height="360" alt="profile" title="Profile" srcset="/images/profile.webp" />
                                  <source width="640" height="360" alt="profile" title="Profile" srcset="/images/profile.png" />
                                  <img src="/images/profile.png" alt="profile" title="Profile" width="640" height="360" />
                                </picture>
                              }
                            </Link>
                          </div>
                          <div className="prodetsecne">
                            <Link to="/profileview">
                              <p className="prnameprt">{localStorage.getItem('ud_fname') + ' ' + localStorage.getItem('ud_lname')}</p>
                              <p className="premailprt">{localStorage.getItem('ud_email')}</p>
                            </Link>
                          </div>
                        </div>
                        {/* <div className="text-center lstlogidetail"><h3>Last Login: 2 days ago</h3></div> */}
                        <div className="text-center"><h3><Link to="/updateprofile">My Profile</Link></h3></div>
                        <div className="text-center"><h3><Link to="/manage-job-alert">Job Alert</Link> </h3></div>
                        <div className="text-center"><h3><Link to="/settings">Setting</Link> </h3></div>
                        <div className="text-center"><h3><Link to="/messages/13">Messages</Link> </h3></div>
                        <div className="text-center"><h3><Link to="/contact-us">Contact us</Link> </h3></div>
                        <div className="text-center"><h3><a href='#' onClick={this.logout}>Logout</a></h3></div>
                      </div>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

        </header>
        { (this.state.profile_status) == '0' ?
          <div class="marqueSect">
            <marquee>⚠ Your profile has been Deactivated and not visible to potential employers, Activate your profile if looking for new Opportunities.!</marquee>
          </div> : null}
      </>
    );
  }

}


const mapStateToProps = (state) => {
  return {
    redirectToHome: state.redirectToHome
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (value) => { dispatch({ type: 'CHANGE_REDIRECT2HOME', payload: value }) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header_after_login);

//export default Header_after_login;
