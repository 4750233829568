import React, { lazy, useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch
} from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import Notifications from 'react-notify-toast';
import {
  PrivateRoute,
  LoginRoute,
  EmpLoginRoute,
  EmpPrivateRoute,
} from './services/Router';
import ScrollToTop from './services/ScrollToTop';

import Home from './pages/home/Index';
// import Profile_update from './pages/Candidate/Profile_update';
// import Job_description from './pages/Candidate/Job_description';
// import Job_alert from './pages/Candidate/Job_alert';
// import Manage_job_alert from './pages/Candidate/Manage_job_alert';
// import Job_search_listing from './pages/Candidate/Job_search_listing';
// import EmployerViewed from './pages/Candidate/EmployerViewed';
// import NotFound from './pages/Candidate/NotFound';
// import Search from './pages/Candidate/Search';

// import AppliedJobs from './pages/Candidate/AppliedJobs';
// import SavedJobs from './pages/Candidate/SavedJobs';
// import Signup from './pages/jobseekerLogin/Signup';
// import Login from './pages/jobseekerLogin/Login';
// import Forgot from './pages/jobseekerLogin/Forgot';
// import Profile_view from './pages/Candidate/Profile_view';
// import Candidate_setting from './pages/Candidate/Candidate_setting';
// import Company_page_view from './pages/Candidate/Company_page_view';
// import Recruiter_profile from './pages/Candidate/Recruiter_profile';

// import Terms from './pages/common/Terms';
// import Privacy from './pages/common/Privacy';
// import About from './pages/Candidate/About';
// import Contact_us from './pages/common/Contact_us';
// import Chat_history from './pages/Candidate/Chat_history';
// //import Chat from  './pages/Candidate/Chat';
// import Set_password from './pages/Candidate/Set_password';
// import Messege_block from './pages/Candidate/Messege_block';
// import Subscription from './pages/Candidate/Subscription';
// import Verify_email_change from './pages/Candidate/Verify_email_change';
// import Verify_account from './pages/Candidate/Verify_account';
import Loader from './services/Loader/Loader';
import Loader2 from './pages/form/Loader';


// import Forgotten_password from './pages/jobseekerLogin/Forgotten_password';
// import Candidate_al_home from './pages/Candidate/Candidate_al_home';

// import Candidate_applied_jobs from './pages/Candidate/Candidate_applied_jobs';

/***************************Employer  Component Import **************/

// import Set_password_emp from './pages/employer/Set_password';
// import Verify_email_change_emp from './pages/employer/Verify_email_change';
// import Verify_account_emp from './pages/employer/Verify_account';

// import Emp_homepage from './pages/home/Emp_homepage';
// import Emp_Signup from './pages/employerlogin/Emp_signup1';

//import Emp_Signup2 from './pages/employerlogin/Emp_signup2'
// import Post_job from './pages/form/Post_job';
// import Edit_post_job from './pages/form/Edit_post_job';

// import Job_post_listing from './pages/Listing/Job_post_listing';
// import Application_listing from './pages/Listing/Application_listing';
// import Shortlisted_candidate from './pages/Listing/Shortlisted_candidate';

// import Candidate_search from './pages/Listing/Candidate_search';
// import Candidate_listing from './pages/Listing/Candidate_listing';
// import Dashboard from './pages/employer/Dashboard';
// import Review_rating_view from './pages/employer/review_rating_view';
// import Rating_edit from './pages/employer/Review_rating';
// import Employer_chat from './pages/employer/Employer_chat';
// import Recruiter_profile_edit from './pages/employer/Recruiter_profile_edit';
// import Company_profile_edit from './pages/employer/Company_profile_edit';

// import Interview_form from './pages/employer/Interview_form';
// import Interview from './pages/employer/Interview';
// import Assesment from './pages/employer/Assesment';
// import Assessment_form from './pages/employer/Assessment_form';

// import Candiate_request from './pages/employer/candidate_search_request';
// import premium_request from './pages/employer/premium_request_view';
// import PremiumEdit from './pages/form/premium_job_edit';


// import Job_description_view from './pages/employer/Job_description_view';

// import Recruiter_profile_view from './pages/employer/Recruiter_profile_view';

// import Candidate_download_history from './pages/employer/Candidate_download_history';
// import Saved_candidate_history from './pages/employer/Saved_candidate_history';
// import Tagged_candidate from './pages/employer/Tagged_candidate';

// import Premium_Post_job from './pages/form/Premium_Post_job';
// import Premium_Job_post_listing from './pages/Listing/Premium_Job_post_listing';

// import Emp_login_in from './pages/employerlogin/Login';

// import Candidate_recomm_job from './pages/Candidate/Candidate_recomm_job';
// import Manage_user from './pages/Listing/Manage_user';
// import User_new from './pages/common/User_new';

// import Order_history from './pages/Listing/Order_history';
// import Allocation_history from './pages/Listing/Allocation_history';
// import Price_Plan from './pages/common/Price_Plan';
import Test from './pages/test/Test';
import ScrollView from './pages/form/ScrollView'

/**********************Lazy load******************************/
const JobLinksSeo = lazy(() => import('./pages/Candidate/JobLinksSeo'));
const Profile_update = lazy(() => import('./pages/Candidate/Profile_update'));
const Job_description = lazy(() => import('./pages/Candidate/Job_description'));
const Job_alert = lazy(() => import('./pages/Candidate/Job_alert'));
const Manage_job_alert = lazy(() => import('./pages/Candidate/Manage_job_alert'));
const Job_search_listing = lazy(() => import('./pages/Candidate/Job_search_listing'));
const EmployerViewed = lazy(() => import('./pages/Candidate/EmployerViewed'));
const NotFound = lazy(() => import('./pages/Candidate/NotFound'));
const Search = lazy(() => import('./pages/Candidate/Search'));

const AppliedJobs = lazy(() => import('./pages/Candidate/AppliedJobs'));
const SavedJobs = lazy(() => import('./pages/Candidate/SavedJobs'));
const Signup = lazy(() => import('./pages/jobseekerLogin/Signup'));
const Login = lazy(() => import('./pages/jobseekerLogin/Login'));
const Forgot = lazy(() => import('./pages/jobseekerLogin/Forgot'));
const Profile_view = lazy(() => import('./pages/Candidate/Profile_view'));
const Candidate_setting = lazy(() => import('./pages/Candidate/Candidate_setting'));
const Company_page_view = lazy(() => import('./pages/Candidate/Company_page_view'));
const Recruiter_profile = lazy(() => import('./pages/Candidate/Recruiter_profile'));

const Terms = lazy(() => import('./pages/common/Terms'));
const Privacy = lazy(() => import('./pages/common/Privacy'));
const About = lazy(() => import('./pages/Candidate/About'));
const Contact_us = lazy(() => import('./pages/common/Contact_us'));
const Chat_history = lazy(() => import('./pages/Candidate/Chat_history'));
//const Chat from  './pages/Candidate/Chat'));
const Set_password = lazy(() => import('./pages/Candidate/Set_password'));
const Messege_block = lazy(() => import('./pages/Candidate/Messege_block'));
const Subscription = lazy(() => import('./pages/Candidate/Subscription'));
const Verify_email_change = lazy(() => import('./pages/Candidate/Verify_email_change'));
const InterviewResponse = lazy(() => import('./pages/Candidate/InterviewResponse'));
const Verify_account = lazy(() => import('./pages/Candidate/Verify_account'));
const Thankyou = lazy(() => import('./pages/Candidate/Thankyou'));
// const Loader = lazy(() => import('./services/Loader/Loader'));
// const Loader2 = lazy(() => import('./pages/form/Loader'));

const Forgotten_password = lazy(() => import('./pages/jobseekerLogin/Forgotten_password'));
const Candidate_al_home = lazy(() => import('./pages/Candidate/Candidate_al_home'));

const Candidate_applied_jobs = lazy(() => import('./pages/Candidate/Candidate_applied_jobs'));

/***************************Employer  Component Import **************/

const Set_password_emp = lazy(() => import('./pages/employer/Set_password'));
const Verify_email_change_emp = lazy(() => import('./pages/employer/Verify_email_change'));
const Verify_account_emp = lazy(() => import('./pages/employer/Verify_account'));

const Emp_homepage = lazy(() => import('./pages/home/Emp_homepage'));
const Emp_Signup = lazy(() => import('./pages/employerlogin/Emp_signup1'));

const Post_job = lazy(() => import('./pages/form/Post_job'));
const Edit_post_job = lazy(() => import('./pages/form/Edit_post_job'));


const Job_post_listing = lazy(() => import('./pages/Listing/Job_post_listing'));
const Application_listing = lazy(() => import('./pages/Listing/Application_listing'));

// new listing
const New_Application = lazy(() => import('./pages/Listing/New_Application'));
// end new listing

const Shortlisted_candidate = lazy(() => import('./pages/Listing/Shortlisted_candidate'));

const Candidate_search = lazy(() => import('./pages/Listing/Candidate_search'));
const Candidate_listing = lazy(() => import('./pages/Listing/Candidate_listing'));
const Dashboard = lazy(() => import('./pages/employer/Dashboard'));
const Review_rating_view = lazy(() => import('./pages/employer/review_rating_view'));
const Rating_edit = lazy(() => import('./pages/employer/Review_rating'));
const Employer_chat = lazy(() => import('./pages/employer/Employer_chat'));
const Recruiter_profile_edit = lazy(() => import('./pages/employer/Recruiter_profile_edit'));
const Company_profile_edit = lazy(() => import('./pages/employer/Company_profile_edit'));

const Interview_form = lazy(() => import('./pages/employer/Interview_form'));
const Interview = lazy(() => import('./pages/employer/Interview'));
const Assesment = lazy(() => import('./pages/employer/Assesment'));
const Assessment_form = lazy(() => import('./pages/employer/Assessment_form'));

const Candiate_request = lazy(() => import('./pages/employer/candidate_search_request'));
const premium_request = lazy(() => import('./pages/employer/premium_request_view'));
const PremiumEdit = lazy(() => import('./pages/form/premium_job_edit'));


const Job_description_view = lazy(() => import('./pages/employer/Job_description_view'));

const Recruiter_profile_view = lazy(() => import('./pages/employer/Recruiter_profile_view'));

const Candidate_download_history = lazy(() => import('./pages/employer/Candidate_download_history'));
const Saved_candidate_history = lazy(() => import('./pages/employer/Saved_candidate_history'));
const Tagged_candidate = lazy(() => import('./pages/employer/Tagged_candidate'));

const Premium_Post_job = lazy(() => import('./pages/form/Premium_Post_job'));
const Premium_Job_post_listing = lazy(() => import('./pages/Listing/Premium_Job_post_listing'));

const Emp_login_in = lazy(() => import('./pages/employerlogin/Login'));

const Candidate_recomm_job = lazy(() => import('./pages/Candidate/Candidate_recomm_job'));
const Manage_user = lazy(() => import('./pages/Listing/Manage_user'));
const User_new = lazy(() => import('./pages/common/User_new'));

const Order_history = lazy(() => import('./pages/Listing/Order_history'));
const Allocation_history = lazy(() => import('./pages/Listing/Allocation_history'));
const Price_Plan = lazy(() => import('./pages/common/Price_Plan'));
const EmpChangePassword = lazy(() => import('./pages/employer/change_password'));



//const history = createBrowserHistory();

// Initialize google analytics page view tracking
//ReactGA.initialize('UA-151243375-1');


/*history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});*/


function App(prop) {

  useEffect(() => {
    //console.log('Trigger .... Page view GA-- ' + window.location.pathname + window.location.search);
    // This line will trigger on a route change
    //ReactGA.set({ page: window.location.pathname });
    //ReactGA.pageview(window.location.pathname + window.location.search);
  });

  if (prop.redirectToHome === true) {
    prop.redirect(false);
    return <Redirect to={'/'} />;
  }



  return (
    <>
      <Suspense fallback={<div><Loader2 pageLoader={true} /><span>Please Wait...</span></div>}>

        <ScrollToTop />
        <Notifications />
        <Loader />
        {/********************* Candidate Module Routes***********************/}
        <Router>

          <Switch>
            <LoginRoute exact path="/" component={Home} />
            <Route exact path="/search/:keywords" component={Search} />
            <Route exact path="/jobs" component={JobLinksSeo} />
            <Route exact path="/jobs/:keywords" component={Search} />
            <Route exact path="/job/:jobid" component={Job_description} />
            <PrivateRoute exact path="/applied-jobs" component={AppliedJobs} />
            <PrivateRoute exact path="/saved-jobs" component={SavedJobs} />
            <PrivateRoute exact path="/employer-viewed" component={EmployerViewed} />
            <PrivateRoute exact path="/updateprofile" component={Profile_update} />
            <PrivateRoute exact path="/jobseeker/registrationcompleted" component={Thankyou} />
            <PrivateRoute exact path="/profileview" component={Profile_view} />
            <PrivateRoute exact path="/settings" component={Candidate_setting} />
            <Route exact path="/company/:companyid" component={Company_page_view} />
            <Route exact path="/recruiter/:recruiterid" component={Recruiter_profile} />
            <PrivateRoute exact path="/messages/:rid" component={Chat_history} />
            <PrivateRoute exact path="/messages" component={Chat_history} />
            {/*<PrivateRoute exact path="/Chat" component={Chat} />*/}
            <PrivateRoute exact path="/job-alert" component={Job_alert} />
            <PrivateRoute exact path="/manage-job-alert" component={Manage_job_alert} />


            <LoginRoute exact path="/jobseeker/registration" component={Signup} />
            <LoginRoute exact path="/jobseeker/login" component={Home} />
            <LoginRoute
              exact
              path="/jobseeker/forgotten-password"
              component={Forgot}
            />

            <Route exact path="/terms-of-use" component={Terms} />
            <Route exact path="/termsofuse" component={Terms} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/about-us" component={About} />
            <Route exact path="/contact-us" component={Contact_us} />

            <Route exact path="/home/responseinterview/:itoken/:etoken/:action" component={InterviewResponse} />
            <Route exact path="/home/account_verify_email_change/:encemail/:encuid" component={Verify_email_change} />
            <Route exact path="/home/account_verify/:encemail/:encuid" component={Verify_account} />
            <Route exact path="/home/change_password/:encemail/:token" component={Set_password} />
            <Route exact path="/home/manage_subscription/:encemail" component={Subscription} />
            <Route exact path="/messege_block" component={Messege_block} />


            <Route exact path="/test" component={Test} />

            {/********************* Employer Module Routes***********************/}
            <EmpLoginRoute exact path="/employer" component={Emp_homepage} />
            <EmpLoginRoute
              exact
              path="/employer/forget_password"
              component={Forgotten_password}
            />
            <EmpLoginRoute
              exact
              path="/employer/registration"
              component={Emp_Signup}
            />
            <EmpPrivateRoute
              exact
              path="/employer/complete-profile"
              component={Emp_Signup}
            />
            <EmpPrivateRoute
              exact
              path="/employer/updateprofile"
              component={Recruiter_profile_edit}
            />
            <EmpPrivateRoute
              exact
              path="/employer/update_company_profile"
              component={Company_profile_edit}
            />

            <EmpPrivateRoute exact path="/employer/post_job" component={Post_job} />
            <EmpPrivateRoute
              exact
              path="/employer/edit_job/:jobid"
              component={Post_job}
            />
            {/* <EmpPrivateRoute exact path="/employer/edit_post_job" component={Edit_post_job} /> */}

            <EmpPrivateRoute
              exact
              path="/employer/my_jobs"
              component={Job_post_listing}
            />
            <EmpPrivateRoute
              exact
              path="/employer/job_view/:job_id"
              component={Job_description_view}
            />
            <EmpPrivateRoute
              exact
              path="/employer/chat/:userId"
              component={Employer_chat}
            />

            <EmpPrivateRoute
              exact
              path="/employer/chat"
              component={Employer_chat}
            />

            <EmpPrivateRoute
              exact
              path="/employer/applied_job_listing/:job_id"
              component={Application_listing}
            />
            <EmpPrivateRoute
              exact
              path="/employer/new-applications"
              component={New_Application}
            />

            <EmpPrivateRoute
              exact
              path="/employer/shortlisted-applications"
              component={New_Application}
            />



            <EmpPrivateRoute
              exact
              path="/employer/shortlisted_candidates/:job_id"
              component={Shortlisted_candidate}
            />


            <EmpPrivateRoute exact path="/assesment" component={Assesment} />
            <EmpPrivateRoute exact path="/new_assessment" component={Assessment_form} />

            <EmpPrivateRoute
              exact
              path="/employer/candidate_search"
              component={Candidate_search}
            />
            <EmpPrivateRoute
              exact
              path="/employer/candidate_listing"
              component={Candidate_listing}
            />
            <EmpPrivateRoute exact path="/employer/Dashboard" component={Dashboard} />
            <EmpLoginRoute exact path="/employer/Emp_login_in" component={Emp_login_in} />
            <EmpPrivateRoute
              exact
              path="/employer/candidate_search_request"
              component={Candiate_request}
            />
            <EmpPrivateRoute
              exact
              path="/employer/premium_request_view/:job_id"
              component={premium_request}
            />
            <EmpPrivateRoute exact path="/employer/manage_user" component={Manage_user} />
            <EmpPrivateRoute exact path="/employer/new_user" component={User_new} />
            <EmpPrivateRoute exact path="/employer/order_history" component={Order_history} />
            <EmpPrivateRoute exact path="/employer/change_password" component={EmpChangePassword} />
            <Route exact path="/employer/pricing" component={Price_Plan} />
            <EmpPrivateRoute
              exact
              path="/employer/allocation_history"
              component={Allocation_history}
            />
            <EmpPrivateRoute
              exact
              path="/employer/Recruiter_profile_view"
              component={Recruiter_profile_view}
            />

            <Route
              exact
              path="/candidate/Job_search_listing"
              component={Job_search_listing}
            />
            <EmpPrivateRoute
              exact
              path="/employer/Premium_Post_job"
              component={Premium_Post_job}
            />
            <EmpPrivateRoute
              exact
              path="/employer/Premium_Job_post_listing"
              component={Premium_Job_post_listing}
            />
            <EmpPrivateRoute
              exact
              path="/employer/Candidate_download_history"
              component={Candidate_download_history}
            />
            <EmpPrivateRoute
              exact
              path="/employer/Saved_candidate_history"
              component={Saved_candidate_history}
            />
            <EmpPrivateRoute
              exact
              path="/employer/Tagged_candidate"
              component={Tagged_candidate}
            />
            <Route
              exact
              path="/employer/scrolview"
              component={ScrollView}
            />

            {/*<Route exact path="/candidate/Sign_up_form" component={Sign_up_form} />
      <Route exact path="/candidate/Login_form" component={Login_form} />
      <Route exact path="/employer/Forgot_password_form" component={Forgot_password_form} /> <Route exact path="/candidate/Candidate_al_home" component={Candidate_al_home} />
	  <Route exact path="/candidate/Candidate_recomm_job" component={Candidate_recomm_job} /> */}

            <EmpPrivateRoute exact path="/employer/review_rating/:id" component={Review_rating_view} />
            <EmpPrivateRoute exact path="/employer/rating_edit/:id" component={Rating_edit} />
            <EmpPrivateRoute exact path="/employer/schedule_interview" component={Interview_form} />
            <EmpPrivateRoute exact path="/employer/schedule_interview/:cv_id" component={Interview_form} />
            <EmpPrivateRoute exact path="/employer/schedule_interview/:cv_id/:position_id" component={Interview_form} />
            <EmpPrivateRoute exact path="/employer/interview" component={Interview} />

            <Route exact path="/employer/forgot_password/:encemail/:token" component={Set_password_emp} />
            <Route exact path="/employer/account_verify_email_change/:encemail/:encuid" component={Verify_email_change_emp} />
            <Route exact path="/employer/account_verify/:encemail/:encuid" component={Verify_account_emp} />

            <Route component={NotFound} />
          </Switch>
        </Router>

        {/* <Redirect to='/' /> */}

        {/*       
      <Route exact path="/candidate/Candidate_applied_jobs" component={Candidate_applied_jobs} /> */}
      </Suspense>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    redirectToHome: state.redirectToHome,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (value) => {
      dispatch({ type: 'CHANGE_REDIRECT2HOME', payload: value });
    },
  };
};


//export default App;
export default connect(mapStateToProps, mapDispatchToProps)(App);
