import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import * as Constant from './../../services/Constant'

import Candidate_Header_after_login from './../common/Candidate_Header_after_login'
import Footer from './../common/Footer'
import Header from './../common/Header'
//import Calendar from 'react-calendar';
//import 'react-calendar/dist/Calendar.css';

import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
const $ = window.$;

class Test extends React.Component {

  constructor(){
    super();
    this.state={ startDate:new Date()}
  }

  componentDidMount(){

}

handleChange = date => {
  this.setState({
    startDate: date
  }
  )}

render() {
  return (
    <>
<div className="main-body">
 
{(localStorage.ud_token)? <Candidate_Header_after_login /> : <Header  transparent={false} />}


<div className="container-fluid">
<div id="rightside" className="rside mar_tp_70">
<div class=" breadcum">
    <ul>
        <li><Link to="/">Home </Link></li>
        <li> Test</li>
    </ul>
    </div>



<div class="abt_us_page_here">
 Testpage<br/>
 <DatePicker
        selected={this.state.startDate}
        onChange={this.handleChange}
      />
<br/>
<input className={"form-control"} value={this.state.startDate} />

        </div>
      </div>
    </div>



  <Footer />
</div>

    </>
  );
}
}

export default Test;